import { useState, memo } from "react";
import Styles from "./TemplateCreation.module.css";
import PREVIEWICON from "../../../../assets/UIUX/icons/Checklist/eye-preview.svg";
import { ClonosButton } from "../../../../components/CommonComponents/Button/Button";
import { useSelector } from "react-redux";
import PLUSICON from "../../../../assets/UIUX/icons/Checklist/white-plus.svg";
import EXPANDDOWN from "../../../../assets/UIUX/icons/Checklist/caret-down.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  commonPageState,
  getFormatedDate,
  globalManipulateTemplatePreviewData,
  globalOnScrollEnd,
  handleShowErrorAndWarning,
} from "../../../../utils/clonosCommon";
import { v4 as uuidv4 } from "uuid";
import { ChecklistAndReportsAction } from "../../../../Store/Reducers/ClonosChecklistAndReportsReducer";
import Modal from "../../../../components/CommonComponents/Modal/Modal";
import CloseIcon from "../../../../assets/Clonos Common Indicators/Icon_Components/CloseIcon";
import { handleGetChecklistStructure, handleGetTemplateVersions, handleGetVersionTemplateDataSets, handleSaveChecklistDataSetsMethod, handleUpdateChecklistDataSets, handleUpdateGeneralTemplate } from "../../../../utils/ChecklistAndReportsMethods/ChecklistMethods";
import { useLocation, useNavigate } from "react-router-dom";
import { UnAuthorizedModal } from "../../../../components/CommonComponents/UnAuthorizedPage/UnAuthorizedModal";
import { Card } from "@material-ui/core";
import { Search, SingleCheck } from "../../../../assets/UIUX/icons/Checklist/Checklist";
import { doGetApiCall, doPostApiCall } from "../../../../utils/ClonosApi";
import AppUrl from "../../../../utils/AppUrl";
import useToggler from "../../../../CustomHooks/TogglerHook";
import ClonosSpinner from "../../../../components/CommonComponents/ClonosSpinner/ClonosSpinner";
import useDebounce from "../../../../CustomHooks/DebounceHook";
import ClonosConfirmationBox from "../../../../components/CommonComponents/ClonosConfirmationBox/ClonosConfirmationBox";
import BackIcon from "../../../../assets/Clonos Common Indicators/Icon_Components/BackIcon";
import TemplateAttributesTable from "../Components/TemplateAttributesTable/TemplateAttributesTable";
import useImagesLoaded from "../../../../CustomHooks/ImageLoaderHook";
import ClonosSkeletonLoading from "../../../../components/CommonComponents/ClonosSkeletonLoading/ClonosSkeletonLoading";
import { TemplateRowActions } from "../Components/TemplateAttributesTable/TemplateRowActions";
import useFieldsChecker from "../../../../CustomHooks/FieldsValidatorHook";
import { SaveAsTemplate } from "../Components/SaveAsTemplate/SaveAsTemplate";
import { TemplatePreview } from "../Components/TemplatePreview/TemplatePreview";
import { TemplateAttributesImporter } from "../Components/TemplateAttributeImporter/TemplateAttributesImporter";
import { commonActions } from "../../../../Store/Reducers/CommonReducer";
import { CLONOS_GLOBAL_APP_ENVIRONMENT, CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_LIMIT, CLONOS_GLOBAL_MAX_ATTRIBUTES, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_BAD_REQUEST_ERROR, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE } from "../../../../Constants/Constant";


// let primaryObject = {};
const TemplateCreation = ({ mode }) => {
  console.log('mode:', mode)
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const LOCATION = useLocation();
  const { state } = LOCATION;
  console.log('state:', state)
  const [showAttributes, setShowAttributes] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [uploadedAttributesFiles, setUploadedAttributesFiles] = useState([]);
  const { templateInfo, checklistTemplateAttributes, checklistGeneralDetailsCreationDetails, useTemplateInformation } = useSelector(
    (store) => store.checklistTemplateData
  );
  console.log('checklistGeneralDetailsCreationDetails:', checklistGeneralDetailsCreationDetails)
  console.log('templateInfo:', templateInfo)
  const [lcValues, setLcValues] = useState({
    checklistCreationLoading: false,
    isSaveAsTemplate: useTemplateInformation?.useTemplate,
    savedTemplateServerResponse: [],
    totalTemplates: null,
    getTemplateDataSetsLoading: false,
    manipulatedDataForAttributesPreview: [],
    saveAsTemplateLoading: false,
    searchTemplate: "",
    checklistStructureDetails: {},
    updatedTemplateLoading: false,
    generalTemplateLoading: false
  });
  const { savedTemplateServerResponse, totalTemplates, getTemplateDataSetsLoading, manipulatedDataForAttributesPreview, checklistStructureDetails, updatedTemplateLoading, generalTemplateLoading } = lcValues;
  console.log('checklistStructureDetails:', checklistStructureDetails)
  const [IsImportTemplateFromLibraryModal, setIsImportTemplateFromLibraryModal] = useState(false);
  const [pagination, setPagination] = useState(commonPageState);
  const [isLoading, setIsLoading] = useToggler();
  const [refreshApi, setRefreshApi] = useToggler();
  const [isImportedTemplatePreviewModal, setIsImportedTemplatePreviewModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const delay = 500; // Set your desired debounce delay
  const debouncedValue = useDebounce(inputValue, delay);
  const [cancelChecklistTemplateCreationAlert, setCancelChecklistTemplateCreationAlert] = useState(false);
  const [localAttributes, setLocalAttributes] = useState([])
  console.log('localAttributes:', localAttributes)

  console.log('savedTemplateServerResponse:', savedTemplateServerResponse)
  console.log('lcValues:', lcValues)

  const isAllImagesLoaded = useImagesLoaded([PLUSICON, EXPANDDOWN])
  const {
    handleGetErrorActivatorInformation,
    handlePrimaryObjectModifier,
    primaryObject,
    handleSubmit,
    handleRemoveField
  } = useFieldsChecker();

  console.log('checklistTemplateAttributes:', checklistTemplateAttributes)

  // Handler Area
  const getSelectedAttributeValue = (props) => {
    const { attributeName, type } = props;

    let tempAttribute = {
      id: uuidv4(),
      asset: null,
      attributeName: attributeName ? attributeName : null,
      fieldName: "",
      fieldValue: "",
      type: type ? type : null
    };


    if (type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE || type === "dropdown" || type === "checkboxes") {
      tempAttribute.fieldValue = [
        { optionId: uuidv4(), optionValue: "", isActive: false },
        { optionId: uuidv4(), optionValue: "", isActive: false },
      ]
    }
    if (useTemplateInformation?.useTemplate) {
      dispatch(ChecklistAndReportsAction?.setUseTemplateInformation({ useTemplate: false, templateId: useTemplateInformation?.templateId }))
      setLcValues(prev => {
        return { ...prev, ["isSaveAsTemplate"]: false, manipulatedDataForAttributesPreview: [] }
      })
    }
    else {
      setLcValues(prev => {
        return { ...prev, ["isSaveAsTemplate"]: false, manipulatedDataForAttributesPreview: [] };
      });
    };


    const attributes = [
      ...checklistTemplateAttributes,
      tempAttribute,
    ];


    setLocalAttributes(attributes);

    dispatch(
      ChecklistAndReportsAction.setSelectedAttributesToolkitState(attributes)
    );
  };

  const getUploadedAttributesFiles = (value) => {
    setUploadedAttributesFiles(value)
  }

  const handleImportTemplateFromLibrary = () => {
    setIsImportTemplateFromLibraryModal(false)
  }

  const handleGetTemplateDataSets = (id, isPreview) => {
    setLcValues(prev => {
      return { ...prev, ["getTemplateDataSetsLoading"]: true }
    })
    doGetApiCall({ url: `${AppUrl.getTemplateDetails}${id}` })
      .then(response => {
        console.log('response:', response)
        setLcValues(prev => {
          let data = globalManipulateTemplatePreviewData({ data: response?.data?.result?.dataSets });
          setLocalAttributes(data);
          if (!isPreview) {
            dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState(data));
            setIsImportTemplateFromLibraryModal(false);
          }
          return { ...prev, ["manipulatedDataForAttributesPreview"]: data };
        });
      }).finally(() => {
        setLcValues(prev => {
          return { ...prev, ["getTemplateDataSetsLoading"]: false };
        });
      });
  };

  const handleChange = (event) => {
    setInputValue(event?.target?.value);
  };

  /**
   * Fetches and updates the template data from the API.
   * 
   * This function handles both paginated and non-paginated requests for fetching template data. 
   * It sets the loading state to true, makes the API call, processes the response, 
   * and updates the state accordingly. If the API call fails, it displays an error message.
   * 
   * @param {boolean} isPagination - A flag indicating whether the request should include pagination parameters.
   * 
   * @returns {void}
   */
  const handleGetSaveAsTemplates = (isPagination) => {
    setIsLoading(true);
    doGetApiCall({
      url: AppUrl?.getSaveAsTemplates,
      params: isPagination ? { page: pagination?.page, limit: pagination?.limit } : { templateName: debouncedValue }
    })
      .then(response => {
        console.log('response:', response);
        const result = {
          data: response?.data?.result?.data,
          status: response?.status,
          totalDataCount: response?.data?.result?.totalDataCount,
        }

        if (response.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
          setLcValues((prev) => {
            if (!result?.data || !Array.isArray(result.data)) {
              console.error('Invalid or missing data in the API response.');
              return prev;
            }
            if (isPagination) {
              let hasMap = {};
              result.data.forEach(item => (hasMap[item?._id] = 1));

              const filteredData = (savedTemplateServerResponse || []).filter(item => !hasMap[item._id]);
              console.log('filteredData:', filteredData)

              return {
                ...prev,
                savedTemplateServerResponse: [...(filteredData || []), ...result.data],
                totalTemplates: result?.totalDataCount
              };
            } else {
              return {
                ...prev,
                savedTemplateServerResponse: result.data,
                totalTemplates: result?.totalDataCount
              };
            }
          });
        }
        else if (response.response?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_BAD_REQUEST_ERROR) {
          handleShowErrorAndWarning({
            dispatch,
            type: "error",
            message: `${response?.response?.data?.errorInfo}.`,
            showTime: 5000
          })
        }
      }).finally(() => {
        setIsLoading(false);
      })
  }


  const handleDeleteKeyFromPrimaryObject = (key) => {
    if (primaryObject[key]) {
      delete primaryObject[key]
    }
  }

  const handleIsAnyFieldUpdate = () => {
    setLcValues(prev => {
      if (manipulatedDataForAttributesPreview?.length > 0) {
        return { ...prev, ["isSaveAsTemplate"]: false, manipulatedDataForAttributesPreview: [] };
      } else {
        return { ...prev, ["isSaveAsTemplate"]: false }
      }
    });
  }


  useEffect(() => {
    if (manipulatedDataForAttributesPreview?.length > 0) {
      for (let key in primaryObject) {
        primaryObject[key].value = true
      };
    };
  }, [manipulatedDataForAttributesPreview?.length]);

  useEffect(() => {
    handleGetSaveAsTemplates(true)
  }, [pagination, refreshApi]);

  // Access the debouncedValue for your logic
  useEffect(() => {
    if (debouncedValue?.length > 0) {
      handleGetSaveAsTemplates(false)
    } else {
      setPagination(prev => {
        return { ...prev, page: 1 }
      })
      setRefreshApi()
    }
  }, [debouncedValue]);

  useEffect(() => {
    globalOnScrollEnd(() => {
      const lcPage = Math.ceil(totalTemplates / pagination?.limit)
      setPagination(prev => {
        if (lcPage !== prev?.page) {
          return { ...prev, page: prev?.page + 1 }
        }
        return prev
      })

    }, "temp1");

  }, [IsImportTemplateFromLibraryModal, refreshApi,]);

  useEffect(() => {
    if (useTemplateInformation?.useTemplate) {
      handleGetTemplateDataSets(useTemplateInformation?.templateId)
    }

    if (mode === "edit") {
      if (LOCATION?.state?.isGeneralTemplate) {
        handleGetTemplateDataSets(LOCATION?.state?.generalTemplateId, false);
      } else {
        handleGetChecklistStructure(
          {
            responseSetterMethod: setLcValues,
            lcStateSetterMethod: setLcValues,
            structureId: LOCATION?.state?.structureId,
            dispatch
          }
        )
      }
    }
    return () => {
      dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState([]))
      // primaryObject = {}
    }

  }, []);

  const handleGetValues = (props) => {
    console.log('props:', props)
    if (props.componentName === "addImageAndNotes") {
      dispatch(ChecklistAndReportsAction.setTemplateInfoToolkitState(props?.updatedValue));
    } else {
      setLocalAttributes(props?.updatedValue);
      dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState(props?.updatedValue));
    }
  }

  const handleRemoveAttribute = (attribute) => {
    setLocalAttributes(prev => {
      console.log('prprevvvvvvvvev:', prev)
      const updatedAttributes = prev?.filter((att) => {
        if (att?.id !== attribute?.id) {
          return true;
        } else {
          // Handle specific cases where the attribute needs additional processing
          console.log("cruise", att, "attribute", attribute)
          let needToDelete = [];
          if (
            attribute?.attributeName === "Multiple Choice" ||
            attribute?.attributeName === "Dropdown" ||
            attribute?.attributeName === "Checkboxes"
          ) {
            if (attribute?.fieldValue?.length > 0) {
              attribute?.fieldValue?.forEach((item) => {
                needToDelete.push(item?.optionId);
              });
            };
          };
          needToDelete.push(attribute?.id)
          handleRemoveField(needToDelete);
          return false;
        }
      });
      console.log('updatedAttributes:', updatedAttributes)
      dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState(updatedAttributes));
      return updatedAttributes
    })
  };

  console.log('lcValues:', lcValues)
  

  return (
    <>
      <Card variant="outlined" style={{ flex: 1, margin: "1rem", borderRadius: "1rem", overflowY: "auto" }}>
        <div aria-label='template main contianer'
          className={Styles.main_container}
        >
          <header aria-label="template top navigation" className={Styles.nav}>

            <div aria-label="import template from template library container">
              <ClonosButton style={{ color: "#0A5FFF", border: "0.5px solid #0A5FFF" }}
                onClick={() => setIsImportTemplateFromLibraryModal(true)}
              >Import From Library</ClonosButton>
              {
                checklistTemplateAttributes?.length > 0 && <div onClick={() => setShowPreviewModal(true)} className={Styles.preview_btn_box}>
                  <img src={PREVIEWICON} />
                  <p>Preview</p>
                </div>
              }
            </div>

            <div aria-label="action control container">
              {CLONOS_GLOBAL_APP_ENVIRONMENT === "development" && <TemplateRowActions
                isImage={true}
                isNote={true}
                isDeselect={false}
                uniqueKey="addImageAndNotes"
                handleGetValues={handleGetValues}
              />}
              <div aria-label="save as template container">
                {
                  lcValues?.isSaveAsTemplate ? <div className={Styles.saved_template_container}> <SingleCheck /> <span>Saved as Template</span></div> :
                    <ClonosButton
                      loading={lcValues?.saveAsTemplateLoading}
                      onClick={() => setShowSaveTemplateModal(true)} style={{ color: "#06337E" }}
                      isDisabled={checklistTemplateAttributes?.length > 0 ? false : true}
                    >
                      Save as Template
                    </ClonosButton>
                }
              </div>

              <TemplateAttributesImporter ariaLabel="template attribute importer"
                getSelectedValue={(val) => {
                  if (checklistTemplateAttributes.length === CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_LIMIT) {
                    handleShowErrorAndWarning({ dispatch, type: "warning", message: `You can only add up to ${CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_LIMIT} attributes in one template.`, showTime: 5000 });
                    return;
                  }
                  getSelectedAttributeValue(val);
                }}
                disabled={checklistTemplateAttributes.length === CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_LIMIT ? true : false}
              />

            </div>

          </header>

          <TemplateAttributesTable
            handleGetValues={handleGetValues}
            attributes={checklistTemplateAttributes}
            uploadedAttributesFiles={uploadedAttributesFiles}
            getUploadedAttributesFiles={(value) => getUploadedAttributesFiles(value)}
            handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
            handlePrimaryObjectModifier={handlePrimaryObjectModifier}
            handleDeleteKeyFromPrimaryObject={handleDeleteKeyFromPrimaryObject}
            handleIsAnyFieldUpdate={handleIsAnyFieldUpdate}
            handleRemoveAttribute={handleRemoveAttribute}
            isEdit={mode === "edit" ? true : false}
          />

          <footer aria-label="component action container"
            className={Styles.btn_controller}>
            <div className={Styles.btns_container}>
              <ClonosButton variant="outlined" onClick={() => {
                setCancelChecklistTemplateCreationAlert(true)
              }}>Cancel</ClonosButton>


              {
                mode === "edit" ?
                  <ClonosButton
                    loading={updatedTemplateLoading || generalTemplateLoading}
                    variant="filled"
                    onClick={() => {
                      if (!handleSubmit()) return;
                      if (LOCATION?.state?.isGeneralTemplate) {
                        handleUpdateGeneralTemplate({
                          lcStateSetterMethod: setLcValues,
                          generalTemplateId: LOCATION?.state?.generalTemplateId,
                          payload: { dataSets: checklistTemplateAttributes?.map((item, index) => ({ ...item, index: index + 1 })), name: LOCATION?.state?.templateName },
                          dispatch,
                          Navigate
                        })
                      } else {
                        handleUpdateChecklistDataSets({
                          structureId: LOCATION?.state?.structureId,
                          payload: {
                            images: [],
                            note: templateInfo?.noteDetails ? templateInfo?.noteDetails : "",
                            dataSets: checklistTemplateAttributes?.map((item, index) => ({ ...item, index: index + 1 })),
                          },
                          dispatch,
                          Navigate,
                          responseSetterMethod: setLcValues
                        });
                      };
                    }}
                  >Updated Template</ClonosButton> :

                  <ClonosButton
                    variant="filled"
                    loading={lcValues?.checklistCreationLoading}
                    isDisabled={checklistTemplateAttributes?.length > 0 ? false : true}
                    onClick={() => {
                      if (!handleSubmit()) return;
                      handleSaveChecklistDataSetsMethod(
                        {
                          checklistId: checklistGeneralDetailsCreationDetails?.id,
                          payload: {
                            images: [],
                            note: templateInfo?.noteDetails ? templateInfo?.noteDetails : "",
                            dataSets: checklistTemplateAttributes?.map((item, index) => ({ ...item, index: index + 1 })),
                          },
                          dispatch,
                          Navigate,
                          isGeneralTemplate: false,
                          responseSetterMethod: setLcValues
                        })
                    }}
                  >Create Checklist</ClonosButton>
              }


            </div>
          </footer>

        </div >
      </Card >
      <Modal isOpen={showPreviewModal} closeModalMethod={setShowPreviewModal}>
        <div className={Styles.preview_modal_sub_container}>
          <div className={Styles.preview_modal_header}>
            <h4>{state?.generalDetails?.name}</h4>
            <div><CloseIcon onClick={() => setShowPreviewModal(false)} /></div>
          </div>
          <div aria-label="General Details" className={Styles.general_details_container}>
            <p>{state?.generalDetails?.description}</p>
          </div>
          <div className={Styles.preview_modal_content_container}>
            <TemplatePreview
              isEdit={true}
              attributes={checklistTemplateAttributes}
            />
          </div>
        </div>
      </Modal>


      {/* Import template from the template library modal */}
      <Modal
        isOpen={IsImportTemplateFromLibraryModal}
        closeModalMethod={setIsImportTemplateFromLibraryModal}
        stopOverlayCloser={true}
      >
        <div className={Styles.import_from_library}>
          <header>
            <span>Import from template library</span>
            <CloseIcon onClick={() => setIsImportTemplateFromLibraryModal(false)} tooltipTitle={"Close"} />
          </header>
          <section className={Styles.import_from_library_body}>
            <div className={Styles.import_from_library_search}>
              <Search onClick={() => setIsImportTemplateFromLibraryModal(false)} />
              <input placeholder="Search" onChange={e => handleChange(e)} />
            </div>
            <div id="temp1" className={Styles.import_from_library_items_container}>
              {
                savedTemplateServerResponse?.map((item) => {
                  return <div key={item.id} className={Styles.import_from_library_body_item}>
                    <div onClick={() => {
                      handleGetTemplateDataSets(item._id, false)
                      primaryObject = {};
                      setLcValues(prev => {
                        return { ...prev, ["isSaveAsTemplate"]: false, activeTemplateDetails: { name: item?.templateName } };
                      });
                      setIsImportTemplateFromLibraryModal(false);
                    }}>
                      <span>{item?.templateName}</span>
                      <span>{getFormatedDate(item.createdAt)}</span>
                    </div>
                    <span onClick={() => {
                      setIsImportedTemplatePreviewModal(true);
                      setLcValues(prev => {
                        return { ...prev, ["activeTemplateDetails"]: { name: item?.templateName } };
                      });
                      handleGetTemplateDataSets(item._id, true);
                    }}>Preview</span>
                  </div>
                })
              }
              <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                {isLoading && <ClonosSpinner />}
              </div>
            </div>
          </section>
          <footer>
            <span onClick={() => setIsImportTemplateFromLibraryModal(false)}>Cancel</span>
            <span onClick={handleImportTemplateFromLibrary}>Ok</span>
          </footer>
        </div>
      </Modal>

      <Modal isOpen={isImportedTemplatePreviewModal} closeModalMethod={setIsImportedTemplatePreviewModal} >
        <div className={Styles.imported_template_Preview_container}>
          <header>
            <div>
              <BackIcon tooltipTitle="Back" onClick={() => setIsImportedTemplatePreviewModal(false)} />
              <span>{lcValues?.activeTemplateDetails?.name}</span>
            </div>
            <CloseIcon tooltipTitle="Close" onClick={() => setIsImportedTemplatePreviewModal(false)} />
          </header>
          {
            getTemplateDataSetsLoading ? <ClonosSpinner /> :
              <TemplatePreview
                isEdit={false}
                attributes={manipulatedDataForAttributesPreview}
                handleUseTemplate={() => {
                  dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState(manipulatedDataForAttributesPreview));
                  setIsImportedTemplatePreviewModal(false);
                  setIsImportTemplateFromLibraryModal(false);
                }}
              />
          }
        </div>
      </Modal>

      <ClonosConfirmationBox
        isOpen={cancelChecklistTemplateCreationAlert}
        confirmingMethod={() => {
          ChecklistAndReportsAction.setSelectedAttributesToolkitState([]);
          ChecklistAndReportsAction.setUseTemplateInformation({ useTemplate: false, templateId: null })
          Navigate("/checklists")
        }}
        closeModalMethod={setCancelChecklistTemplateCreationAlert}
        headerTitle="Template Creation"
        message="Are you sure, You don't want to create checklist template?"
      />

      <SaveAsTemplate
        open={showSaveTemplateModal}
        closeMethod={setShowSaveTemplateModal}
        checklistGeneralDetailsCreationDetails={checklistGeneralDetailsCreationDetails}
        responseSetterMethod={setLcValues}
        loading={lcValues?.saveAsTemplateLoading}
        refreshApi={setRefreshApi}
      />
    </>
  );
  // return <UnAuthorizedModal />
};

export default memo(TemplateCreation);
