import React, { useEffect, useState, useRef } from 'react'

import "./Test.css"


const Button = ({ children, onClick }) => {
    const buttonRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false); // State for click effect

    const handleClick = () => {
        setIsClicked(true); // Trigger click effect on click
        onClick && onClick(); // Call provided onClick function
    };

    // const handleMouseDown = (event) => {
    //     const button = buttonRef.current;
    //     button.classList.add('hold-mouse');
    //     console.log('event', event);

    //     const x = event.offsetX - 10;
    //     const y = event.offsetY - 10;

    //     const circle = document.createElement('div');
    //     circle.classList.add('circle', 'grow');
    //     circle.style.left = `${x}px`;
    //     circle.style.top = `${y}px`;

    //     button.parentNode.appendChild(circle); // Append to parent for proper positioning
    // };


    const handleMouseDown = (event) => {
        const button = buttonRef.current;
        button.classList.add('hold-mouse');
        console.log('event', event);

        const { clientX, clientY } = event; // Get click coordinates relative to viewport
        const buttonRect = button.getBoundingClientRect(); // Get button's position and size

        const relativeX = clientX - buttonRect.left; // Calculate click position within button
        const relativeY = clientY - buttonRect.top;

        const circle = document.createElement('div');
        circle.classList.add('circle', 'grow');
        circle.style.left = `${relativeX}px`;
        circle.style.top = `${relativeY}px`;

        button.parentNode.appendChild(circle); // Append to parent for proper positioning
    };

    
    const handleMouseUp = () => {
        const button = buttonRef.current;
        button.classList.remove('hold-mouse');

        setTimeout(() => {
            const circles = button.parentNode.querySelectorAll('.circle');
            circles.forEach(element => element.remove());
        }, 300); // Adjust delay as needed
    };

    return (
        <div className="container">
            <button
                ref={buttonRef}
                className={`button ${isClicked ? 'hold-mouse' : ''}`} // Apply 'hold-mouse' on click
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onClick={handleClick}
            >
                <div className="circle"></div>
                {children}
            </button>
        </div>
    );
};

export default Button;

