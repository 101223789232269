import React, { useState } from 'react'
import { Card } from '@mui/material'
import defaultImportImage from "../assets/UIUX/images/import.png"
import ThreeDViewer from '../components/CommonComponents/ThreeDViewer/ThreeDViewer'
import Test from './Test'
import useModuleNameProvider from '../CustomHooks/ModuleNameProviderHook'
import TemplateCreation from '../Clonos_Modules/ChecklistAndReports/ClonosTemplate/TemplateCreation/TemplateCreation'
import Button from "./Test"

const Index = () => {
    // const moduleName = useModuleNameProvider("rohan")
    const [file, setFile] = useState(null);

    const handleCheckUrl = (e) => {
        console.log('e:', e)
        setFile(e)
    }

    const handleGetEditedData = (props) => {
        console.log('props:', props)
    }
    return (
        <Card variant="outlined" style={{ flex: 1, margin: "1rem", borderRadius: "1rem", overflowY: "auto" }}>
            {/* <ThreeDViewer
                file={""}
                url={'https://saclonosongcdevalpha.blob.core.windows.net/uploaded/asm-7bc0a66d-6604-4965-813c-45ed0eb1cfdc_asset3DModel.fbx?se=2024-03-28T12%3A28%3A30Z&sp=rw&sv=2018-03-28&sr=b&sig=6z28ap8RuBbUJ9%2FPthBi323ChGhXzS4OiRDOS6JlI7k%3D'}
                height={600}
                width={1000}
            /> */}
            <Button>Hello baby</Button>
        </Card>

    )
}

export default Index