export const prodConfigs = {

    // url: "http://20.219.61.253:3333"
    // url:"http://20.198.122.177"
    // url: "http://20.204.85.50:3333"
    // url: "http://192.168.68.109:3333"

    // url: "http://20.204.179.207:3334"

    // url: "http://192.168.68.120:3333"
    // url:"http://localhost:3333"

    // url: "https://bpcl-kci.clonos.in:3333"

    // ekta local 
    // url: "http://192.168.68.111:3333"
    // url: "http://192.168.68.115:3333"
    // mine local
    // url: "http://192.168.68.117:3334"

    // Dummy Testing Con.
    // url : "https://as-clonosv1-kaleesuwari-prod.azurewebsites.net"
    url : "https://as-clonosv1-kaleesuwari-prod.azurewebsites.net"

    // Triel
    // url: "http://98.70.48.5:3333"
}   
