import React from 'react'
import Styles from "./ClonosSelect.module.css"
import { useState, useEffect } from "react"
import useToggler from '../../../CustomHooks/TogglerHook'
import { generateUniqueId, handleGetHeightOrWidthBasedOnId } from '../../../utils/clonosCommon'
import commonDownArrowIcon from "../../../assets/UIUX/icons/Common_Icon/COMMON_DOWN_ARROW_ICON.svg"
import { ErrorSVGCom } from '../../../assets/svg/ErrorHandling'
import { CLONOS_GLOBAL_COLOUR_RED, CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, CLONOS_GLOBAL_ERROR_HANDLING_SIZE } from '../../../Constants/Constant'
import ClonosRadioAndCheckbox from '../ClonosRadio&Checkbox/ClonosRadio&Checkbox'
import { IoIosClose } from 'react-icons/io'

const lcPositions = {
    top: {},
    bottom: {},
    left: {},
    right: {}
}


/**
 * ClonosSelect component for rendering a custom select dropdown.
 * @param {Object} props - Component props.
 * @property {string} type - Type of the select input.
 * @property {string} placeholder - Placeholder text for the select input.
 * @property {string} position - Position of the options container ("top" or "bottom").
 * @property {string} label - Label for the select input.
 * @property {boolean} isLabel - Flag to show/hide the label.
 * @property {string} uniqueKey - Unique key for the component.
 * @property {string} defaultValue - Default value for the select input.
 * @property {boolean} isMandatory - Flag indicating whether the field is mandatory.
 * @property {function} handleGetValues - Callback function to handle value changes.
 * @property {Array} options - Array of options for the select input and each option is an object that take these three keys {label:"string",value:"string",isNeeded:"boolean",disabled:"boolean"}.
 * @property {string} errorMessage - Error message to display for mandatory fields.
 * @property {string} areaLabel - Description of the area.
 * @property {object} labelStyle - For style the label.
 * @property {string} optionType - Will define the type of the options we are rendering (checkbox || radio) and by default it will be "standard".
 * @property {object} optionStyle - For style the option.
 * @property {number} tabIndex - tabIndex for input tag.
 * @property {boolean} disabled - for disalbe the whole component.
 * @property {string} indexUniqueKey - Unique Index key for component when we use component in the looping.
 * @property {function} handleGetErrorActivatorInformation - This function will handle all kind of error handling, that returns one object.
 * @property {function} handleGetErrorActivatorInformation.errorActivatorMethod  - This function will trigger or allow to show the error, it takes boolean value (true/false).
 * @property {function} handleGetErrorActivatorInformation.errorSetterMethod  - This function can set the error massage, it takes string.
 * @property {function} handleGetErrorActivatorInformation.type  - "type" represent that which kind of components is it like, "input, select, textarea"
 * @property {function} handleGetErrorActivatorInformation.uniqueKey  - "uniqueKey" will be be unique string that represent specific element.
 * @property {function} handleGetErrorActivatorInformation.value  - "value" Initially will be false that you need to change once that specific element has come value. and make it as true.
 * @returns {React.Component} - ClonosSelect component.
 */

const ClonosSelect = React.memo(({
    type,
    label,
    value,
    options = [],
    isLabel,
    tabIndex,
    areaLabel,
    disabled,
    uniqueKey,
    labelStyle,
    optionStyle,
    placeholder,
    errorMessage,
    defaultValue,
    indexUniqueKey,
    position = "bottom",
    isMandatory = false,
    handleGetValues = null,
    optionType = "standard",
    // optionType = "checkbox",
    // optionType = "radio",    
    handleGetErrorActivatorInformation,
}) => {
    const [lcValue, setLcValue] = useState(value || null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [optionsWrapper, setOptionsWrapper] = useState(false);
    const [lcErrorMessage, setLcErrorMessage] = useState(errorMessage || `Please select ${label ? label?.toLowerCase() : "this"} field!`);
    const [positionStyle, setPositionStyle] = useState({});
    const [lcOptions, setLcOptions] = useState(options);



    /**
     * Handle click on a select option.
     * @param {Object} props - The selected option.
     */
    const handleClickOnOption = (props) => {
        setShowErrorMessage(false);
        if (optionType === "radio" || optionType === "checkbox") {
            setLcOptions(state => {
                let updatedValue = null;
                if (optionType === "radio") {
                    updatedValue = (state || []).map(item => {
                        if (item.value === props.value) {
                            return { ...item, isChecked: true }
                        } else {
                            return { ...item, isChecked: false };
                        }
                    });
                }
                else {
                    updatedValue = (state || []).map(item => {
                        if (item.value === props.value) {
                            return { ...item, isChecked: !item.isChecked }
                        } else {
                            return item;
                        }
                    });
                }
                if (optionType === "checkbox") {
                    const selectedValues = (updatedValue || []).filter(item => item.isChecked);
                    setLcValue(selectedValues);
                    handleGetValues && handleGetValues({ type, uniqueKey, optionType, indexUniqueKey, updatedValue: selectedValues });
                } else {
                    setLcValue(props);
                    setOptionsWrapper(!optionsWrapper);
                    handleGetValues && handleGetValues({ type, uniqueKey, optionType, indexUniqueKey, updatedValue: props });
                }
                if (isMandatory) {
                    setLcErrorMessage(props ? null : errorMessage || `Please select ${label ? label?.toLowerCase() : "this"} field!`)
                }
                return updatedValue
            })
        } else {
            setLcValue(props);
            setOptionsWrapper(!optionsWrapper);
            handleGetValues && handleGetValues({ type, uniqueKey, optionType, indexUniqueKey, updatedValue: props });
            if (isMandatory) {
                setLcErrorMessage(props ? null : errorMessage || `Please select ${label ? label?.toLowerCase() : "this"} field!`)
            }
        }
    };

    const handleReset = () => {
        setTimeout(() => {
            setLcValue(value || null);
        }, 100);
    };

    useEffect(() => {
        defaultValue?.length > 0 && setLcValue(defaultValue);
        if (isMandatory) {
            if (!defaultValue)
                setLcErrorMessage(errorMessage || `Please select ${label ? label?.toLowerCase() : "this"} field!`)
            else
                setLcErrorMessage(null);
        }
        handleGetErrorActivatorInformation && isMandatory && handleGetErrorActivatorInformation({
            type,
            uniqueKey,
            indexUniqueKey,
            errorActivatorMethod: setShowErrorMessage,
            errorSetterMethod: setLcErrorMessage,
            value: (typeof (defaultValue) == "string" || defaultValue?.length > 0) || (typeof (defaultValue) == "array" || defaultValue?.length > 0) ? true : false,
            resetMethod: handleReset
        })

        let temp = handleGetHeightOrWidthBasedOnId({ idName: "clonos_select_container" })

    }, [defaultValue]);

    useEffect(() => {
        if (!defaultValue) {
            setLcValue(value || null);
            if (isMandatory) {
                if (!value)
                    setLcErrorMessage(errorMessage || `Please select ${label ? label?.toLowerCase() : "this"} field!`)
                else
                    setLcErrorMessage(null);
            }
        }
    }, [value]);


    useEffect(() => {
        switch (position) {
            case "top": {
                setPositionStyle(
                    {
                        bottom: `${handleGetHeightOrWidthBasedOnId({ idName: "clonos_select_container" }).height + 10}px`,
                    }
                )
            }
                break;
            case "bottom": {
                setPositionStyle(
                    {
                        top: `${handleGetHeightOrWidthBasedOnId({ idName: "clonos_select_container" }).height + 10}px`
                    }
                )
            }
                break;

        }
    }, []);

    // For updating the local options state
    useEffect(() => {
        if (options?.length > 0 && options[0]?.uniqueKey) {
            setLcOptions(prev => {
                options?.forEach(item => {
                    if (item?.uniqueKey && lcValue?.uniqueKey && (item?.uniqueKey === lcValue?.uniqueKey)) {
                        setLcValue(item);
                    }
                });
                let hasMap = {};
                prev?.forEach(item => {
                    hasMap[item.uniqueKey] = item
                })
                const updatedState = options?.map(item => {
                    return { ...hasMap[item?.uniqueKey], label: item?.label, value: item?.value }
                })
                return updatedState;
            });
        } else {
            setLcOptions(options);
        }
    }, [options]);

    return (
        <div aria-label={areaLabel} className={Styles.clonos_select_container}
            id='clonos_select_container'
        >
            {isLabel && (
                <label style={{ ...labelStyle }}>
                    {label} {isMandatory && <sup className={Styles.clonos_select_isMandatory}>*</sup>}
                </label>
            )}
            <div className={Styles.clonos_select_wrapper}>
                <div
                    tabIndex={tabIndex}
                    className={Styles.clonos_select}
                    style={
                        {
                            zIndex: '0',
                            borderColor: showErrorMessage ? CLONOS_GLOBAL_COLOUR_RED : "#8CA1C4",
                            cursor: disabled ? "not-allowed" : "pointer",
                            backgroundColor: disabled ? CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT : ""
                        }
                    }
                    onClick={() => {
                        if (disabled) return;
                        optionsWrapper && lcValue === null && isMandatory && setShowErrorMessage(true);
                        setOptionsWrapper(!optionsWrapper);
                    }}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            // e.currentTarget.click();
                            setOptionsWrapper(!optionsWrapper);
                        }
                    }}
                >
                    <span>{lcValue?.label ? lcValue?.label : defaultValue ? defaultValue : value ? value : placeholder}</span>
                    <img src={commonDownArrowIcon} style={{ transform: position == "top" ? optionsWrapper ? "rotate(360deg)" : "rotate(180deg)" : optionsWrapper && "rotate(180deg)", transition: ".1s all ease-in-out" }} />
                </div>
                <div
                    className={Styles.clonos_select_options_container}
                    style={{ ...positionStyle, display: optionsWrapper ? 'flex' : 'none' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    {lcOptions?.map((option, index) => {
                        return option?.isNeeded && <div
                            key={generateUniqueId(5)}
                            className={Styles.clonos_select_option}
                            onClick={() => {
                                option?.fn && option?.fn();
                                handleClickOnOption(option);
                            }}
                        >
                            {
                                optionType !== "standard" ? <div
                                    className={Styles.option_type_container}
                                >
                                    <ClonosRadioAndCheckbox
                                        type={optionType}
                                        name="radio"
                                        disabled={option?.disabled}
                                        isChecked={option?.isChecked ? option?.isChecked : false}
                                    />
                                    <button
                                        disabled={option?.disabled}
                                        style={{ ...optionStyle }}
                                    >{option.label}</button>
                                </div> : <button
                                    disabled={option?.disabled}
                                    style={{ ...optionStyle }}
                                >{option.label}</button>
                            }
                        </div>
                    })}
                    {
                        options?.length == 0 && <p style={{ width: "100%", textAlign: "center", fontSize: "14px", color: "gray", cursor: "default" }}>No Options</p>
                    }
                </div>
                <div
                    className={Styles.clonos_select_overlay}
                    style={{ display: optionsWrapper ? 'block' : 'none' }}
                    onClick={() => {
                        optionsWrapper && lcValue === null && isMandatory && setShowErrorMessage(true);
                        setOptionsWrapper(false);
                    }}
                ></div>
            </div>
            {
                optionType === "checkbox" && <div className={Styles.ddi_checkbox_controller}>
                    {
                        (lcValue !== null && lcValue?.length > 0) && (lcValue || []).map((ele) => {
                            return <div className={Styles.ddi_checkbox_controller_item}><span>{ele.label}</span>
                                <IoIosClose fontSize="23px"
                                    onClick={() => {
                                        setLcValue(prev => {
                                            const updatedValue = prev.filter((lcItem => lcItem.value !== ele.value))
                                            handleGetValues && handleGetValues({ type, optionType, uniqueKey, indexUniqueKey, updatedValue });
                                            if (updatedValue?.length == 0) {
                                                setLcErrorMessage(`Please select ${label ? label?.toLowerCase() : "this"} field!`)
                                                setShowErrorMessage(true);
                                            }
                                            return updatedValue
                                        });
                                        setLcOptions(lcOptions => {
                                            return lcOptions.map(lcOption => {
                                                if (lcOption.value === ele.value) {
                                                    return { ...lcOption, isChecked: false }
                                                } else {
                                                    return lcOption
                                                }
                                            })
                                        })

                                    }} /> </div>
                        })
                    }
                </div>
            }
            {showErrorMessage && isMandatory && lcValue != "null" && <span
                style={{ color: CLONOS_GLOBAL_COLOUR_RED }}
                className={Styles.clonos_select_error}>
                <ErrorSVGCom size={CLONOS_GLOBAL_ERROR_HANDLING_SIZE} color={CLONOS_GLOBAL_COLOUR_RED} />{lcErrorMessage}</span>}
        </div >
    );
});

export default ClonosSelect;