export const localConfigs = {
    // dev alpha/
    // url: "http://20.204.85.50:3333"
    // url: "http://192.168.68.109:3333"

    // test
    // url: "http://20.204.179.207:3334"
    //derin local
    // url:"http://192.168.68.114:3333"   
    // url:"http://192.168.1.2:3333"   

    // backend Ip Chola MS
    // url:"http://20.207.204.144:3334" 

    // url:"http://192.168.1.4:3333"
    // url:"http://localhost:3333"

    // adlin local 
    // url:"http://192.168.68.128:3333"

    // production
    // url:"http://20.198.122.177:3333"

    // demo 
    // url:"http://4.240.123.134:3333"

    // My Local
    // url: "http://192.168.68.122:3333"

    // Indhu Local
    // url: "http://192.168.68.113:3333"

    // ekta local 
    // url: "http://192.168.68.111:3334"

    // mine local
    // url: "http://192.168.68.117:3333"
    

    url: "https://as-clonosv1-kaleesuwari-prod.azurewebsites.net"
};
