import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: []
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsToolkitState(state, actions) {
      console.log('actions:', actions)
      state.notifications = actions.payload
    }
  },
});
export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;
