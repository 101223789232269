import { CLONOS_GLOBAL_APP_ENVIRONMENT, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS } from "../Constants/Constant";
import AppUrl from "./AppUrl";
import { doGetApiCall } from "./ClonosApi";
import { debounce } from "./clonosCommon";



const autoSaveFieldsToLocalStorage = ({key,data ={},delay=500}) => {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(key)) || {};
    const auxData = {...dataFromLocalStorage,...data};
    console.log(auxData,"auxData")
    localStorage.setItem(key,JSON.stringify(auxData))
}

export const debouncedSaveFildsToLocalStorage = debounce(autoSaveFieldsToLocalStorage, 500);


export const getAssetDropdownData = ({setterMethod,setIsLoading,pagination}) => {
    setIsLoading();
    doGetApiCall({url:AppUrl.getAssets,params:pagination})
    .then((res) => {
        if(res?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS){
            setterMethod(res?.data?.result)
        }
    })
    .catch(err => console.error(err))
    .finally(() => setIsLoading())
}


/**
 * Modifies the behavior of `console.log` to include a timestamp
 * when the environment is set to "test". It only affects logs
 * in the test environment and preserves the original `console.log`
 * functionality.
 *
 * @param {string} CLONOS_GLOBAL_APP_ENVIRONMENT - A global variable 
 * indicating the application's current environment. If it is set to 
 * "test", the `console.log` method will be modified.
 */
export const consoleModifier = () => {
    if (CLONOS_GLOBAL_APP_ENVIRONMENT === "test") {
        const originalConsole = console.log;
        console.log = function (...args) {
            const timeStamp = new Date().toLocaleString();
            originalConsole.apply(console, [`[${timeStamp}]`, ...args]);
        }
    } else if(CLONOS_GLOBAL_APP_ENVIRONMENT === "production"){
        console.log = function(){}
    }
}