import { prodConfigs } from './prod.config.js';
import { localConfigs } from './local.config.js';


let configs = {};
if (process.env.REACT_APP_BUILDTYPE === "TESTING") {
    // configs = { url: process.env.REACT_APP_LOCAL_CONFIG }
    configs = localConfigs
}
else {
    // configs = { url: process.env.REACT_APP_PRODUCTION_CONFIG }
    configs = prodConfigs
}

export default configs
