
// LOGIN PASSWORD ENCRYPT SECRET KEY
export const CLONOS_ENCRYPT_PASSWORD_SECRET_KEY = "WEB@CLONOS"
export const CLONOS_LOGIN_SOURCE_KEY = "#CLONOS@web"


export const CLONOS_GLOBAL_VENDOR = "TEST"

// App Environments
// export const CLONOS_GLOBAL_APP_ENVIRONMENT = "development"  // eNum = development || production
// export const CLONOS_GLOBAL_APP_ENVIRONMENT = "test"  // eNum = development || production
export const CLONOS_GLOBAL_APP_ENVIRONMENT = "production"  // eNum = development || production

// App Version
export const CLONOS_GLOBAL_APP_VERSION = "1.1.13";
export const CLONOS_GLOBAL_APP_UI_VERSION = "3.3";

// COLOR CONSTANT 
export const CLONOS_GLOBAL_COLOUR_WHITE = "#FFFFFF";
export const CLONOS_GLOBAL_COLOUR_GREEN = '#60D468'
export const CLONOS_GLOBAL_COLOUR_ORANGE = '#E5B82F'
export const CLONOS_GLOBAL_COLOUR_BLUE = "#06337E";
export const CLONOS_GLOBAL_COLOUR_RED = "#D24B5A";

export const CLONOS_GLOBAL_COLOUR_light = "#E6E6E6";
export const CLONOS_GLOBAL_COLOUR_FADE_WHITE = " #fafafa";
export const CLONOS_GLOBAL_COLOUR_BLUE_HOVER = "#0A5FFF";
export const CLONOS_GLOBAL_HOVER_COLOUR = "rgba(0, 0, 0, 0.06)"
export const CLONOS_GLOBAL_HOVER_COLOUR_SKY_LIGHT = ' #e7efff'
export const CLONOS_GLOBAL_COLOUR_TERTAIRY_GREEN = "#EBFAEC"
export const CLONOS_GLOBAL_COLOUR_TERTAIRY_YELLOW = "#F7E9BF"

export const CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_DARK = "#a0a0a0";
export const CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT = "#E6EBF2";
export const CLONOS_GLOBAL_DISABLED_COLOUR_TEXT_LIGHT = "#a0a0a09e";
export const CLONOS_GLOBAL_MATE_BLACK = "#575757"
export const CLONOS_GLOBAL_COLOR_ICON_BLACK = "#1C1C1C"

// ERROR HANDLING SVG HEIGHT AND WIDTH CONSTANT
export const CLONOS_GLOBAL_ERROR_HANDLING_SIZE = "15";
export const CLONOS_GLOBAL_ERROR_HANDLING_TOP_MARGIN = "0.75rem"

// Backend Response Status
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS = 201;
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS = 200;
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_DELETE_SUCCESS = 200;
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS = 200;
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_BAD_REQUEST_ERROR = 400;
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_INTERNAL_SERVER_ERROR = 500;
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_UNAUTHORIZED_ERROR = 401;
export const CLONOS_GLOBAL_SERVER_RESPONSE_CODE_NOTFOUND_ERROR = 404;

// User Roles
export const CLONOS_GLOBAL_USER_TYPE_ADMIN = "Admin"
export const CLONOS_GLOBAL_USER_TYPE_MANAGER = "Manager"
export const CLONOS_GLOBAL_USER_TYPE_ENGINEER = "Engineer"
export const CLONOS_GLOBAL_USER_TYPE_OPERATOR = "Operator"


// Commom Permissions 
export const CLONOS_GLOBAL_ACTION_CREATE = "create";
export const CLONOS_GLOBAL_ACTION_READ = "read";
export const CLONOS_GLOBAL_ACTION_UPDATE = "update";
export const CLONOS_GLOBAL_ACTION_DELETE = "delete";
export const CLONOS_GLOBAL_ACTION_COPY = "copy";
export const CLONOS_GLOBAL_ACTION_DOWNLOAD = "download";
export const CLONOS_GLOBAL_ACTION_DECOMMISSION = "decommission";
export const CLONOS_GLOBAL_ACTION_RESTORE = "restore";
export const CLONOS_GLOBAL_ACTION_DUE_DATE_EXTENSION = "dueDateExtension";
export const CLONOS_GLOBAL_ACTION_FILL_ENTRY = "fillEntry";
export const CLONOS_GLOBAL_ACTION_FILL_UPDATE_ENTRY = "updateFillEntry";

// Module Names 
export const CLONOS_GLOBAL_MODULE_DASHBOARD = "dashboard";
export const CLONOS_GLOBAL_MODULE_USERS = "users";
export const CLONOS_GLOBAL_MODULE_TEAMS = "teams";
export const CLONOS_GLOBAL_MODULE_ASSETS = "assets";
export const CLONOS_GLOBAL_MODULE_DOCUMENTS = "documents";
export const CLONOS_GLOBAL_MODULE_SPARES = "sparesAndInventory"
export const CLONOS_GLOBAL_MODULE_WORK_ORDERS = "workOrders";
export const CLONOS_GLOBAL_MODULE_CHECKLISTS = "checklists";
export const CLONOS_GLOBAL_MODULE_LOGS = "logs";
export const CLONOS_GLOBAL_MODULE_TASK_LIBRARY = "taskLibrary";
export const CLONOS_GLOBAL_MODULE_MAINTENANCE_PLANS = "maintenancePlans";
export const CLONOS_GLOBAL_MODULE_PLANT_3D = "plant3D";
export const CLONOS_GLOBAL_MODULE_3D_LIBRARY = "3DLibrary"
export const CLONOS_GLOBAL_MODULE_REPORTS = "reports"
export const CLONOS_GLOBAL_MODULE_AUDIT_AND_INSPECTION = "auditAndInspection"


export const CLONOS_GLOBAL_TIME_PERIOD_HOUR = "Hour";
export const CLONOS_GLOBAL_TIME_PERIOD_DAY = "Day";
export const CLONOS_GLOBAL_TIME_PERIOD_WEEK = "Week";
export const CLONOS_GLOBAL_TIME_PERIOD_MONTH = "Month";
export const CLONOS_GLOBAL_TIME_PERIOD_YEAR = "Year";

export const CLONOS_GLOBAL_SPECIFIC_DAY_MONDAY = "monday"
export const CLONOS_GLOBAL_SPECIFIC_DAY_TUESDAY = "tuesday"
export const CLONOS_GLOBAL_SPECIFIC_DAY_WEDNESDAY = "wednesday"
export const CLONOS_GLOBAL_SPECIFIC_DAY_THURSDAY = "thursday"
export const CLONOS_GLOBAL_SPECIFIC_DAY_FRIDAY = "friday"
export const CLONOS_GLOBAL_SPECIFIC_DAY_SATURDAY = "saturday"
export const CLONOS_GLOBAL_SPECIFIC_DAY_SUNDAY = "sunday"

export const CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_LIMIT = 100;
export const CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT = 10;
export const CLONOS_GLOBAL_LOG_TEMPLATE_ATTRIBUTES_LIMIT = 100;
export const CLONOS_GLOBAL_LOG_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT = 10;


export const CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_TEXT = "text";
export const CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE = "multiplechoice";
export const CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_CHECKBOXES = "checkboxes";
export const CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DROPDOWN = "dropdown";
export const CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER = "number";
export const CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE = "date";


export const CLONOS_GLOBAL_STATUS_DRAFT = "draft";
export const CLONOS_GLOBAL_STATUS_SCHEDULED = "scheduled";
export const CLONOS_GLOBAL_STATUS_IN_PROGRESS = "inProgress";
export const CLONOS_GLOBAL_STATUS_COMPLETED = "completed";
export const CLONOS_GLOBAL_STATUS_ACCEPTED = "accepted";
export const CLONOS_GLOBAL_STATUS_ON_HOLD = "onHold";
export const CLONOS_GLOBAL_STATUS_CONTINUE = "continue";
export const CLONOS_GLOBAL_STATUS_EXPIRED = "expired";
export const CLONOS_GLOBAL_STATUS_PENDING_APPROVAL = "pendingApproval";
export const CLONOS_GLOBAL_STATUS_PENDING_FOR_APPROVAL = "pendingForApproval";
export const CLONOS_GLOBAL_STATUS_PENDING = "pending";
export const CLONOS_GLOBAL_STATUS_REVISED = "revised";
export const CLONOS_GLOBAL_STATUS_OVERDUE = "overdue";
export const CLONOS_GLOBAL_STATUS_PART_REQUESTED = "partRequested";
export const CLONOS_GLOBAL_STATUS_WORK_IN_PROGRESS = "workInProgress";
export const CLONOS_GLOBAL_STATUS_ACTIVE = "active";
export const CLONOS_GLOBAL_STATUS_UNDER_MAINTENANCE = "underMaintenance";
export const CLONOS_GLOBAL_STATUS_BREAKDOWN = "breakdown";
export const CLONOS_GLOBAL_STATUS_STANDBY = "standby";
export const CLONOS_GLOBAL_STATUS_DECOMMISSIONED = "decommissioned";


export const CLONOS_GLOBAL_STATUS_UNDEFINED = "undefined";

// NOTIFICATION ACTIVITY ENUMS

export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_CREATE_ENUM = "create";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_EDIT_ENUM = "edit";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_DELETE_ENUM = "delete";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_DECOMMISSION_ENUM = "decommission";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_RESTORE_ENUM = "restore";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_STATUS_CHANGE_ENUM = "statusChange";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_SCHEDULED_ENUM = "scheduled";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_REMARKS_ENUM = "remarks";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_ASSIGN_ENUM = "assign";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_UNASSIGNED_ENUM = "unAssigned";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_REVISE_ENUM = "revise";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_APPROVE_ENUM = "approve";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_DATA_ENTRY_UPDATE_ENUM = "dataEntryUpdate";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_EXPIRED_ENUM = "expired";
export const CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_LIMIT_BREACHED_ENUM = "setLimitBreached";

// APP CONFIGRATION TIME ZONE CONFIGRATION
export const CLONOS_GLOBAL_SERVER_TIME_ZONE = "UTC";
export const CLONOS_GLOBAL_CLIENT_TIME_ZONE = "IST";

// TOTAL TIME ZONES
export const CLONOS_GLOBAL_TIME_ZONE_IST = "IST";  // Indian Standard Time (UTC +5:30)
export const CLONOS_GLOBAL_TIME_ZONE_UTC = "UTC";  // Coordinated Universal Time (UTC)
export const CLONOS_GLOBAL_TIME_ZONE_GMT = "GMT";  // Greenwich Mean Time (UTC +0)
export const CLONOS_GLOBAL_TIME_ZONE_EST = "EST";  // Eastern Standard Time (UTC -5:00)
export const CLONOS_GLOBAL_TIME_ZONE_EDT = "EDT";  // Eastern Daylight Time (UTC -4:00)
export const CLONOS_GLOBAL_TIME_ZONE_PST = "PST";  // Pacific Standard Time (UTC -8:00)
export const CLONOS_GLOBAL_TIME_ZONE_PDT = "PDT";  // Pacific Daylight Time (UTC -7:00)
export const CLONOS_GLOBAL_TIME_ZONE_CST = "CST";  // Central Standard Time (UTC -6:00)
export const CLONOS_GLOBAL_TIME_ZONE_CDT = "CDT";  // Central Daylight Time (UTC -5:00)
export const CLONOS_GLOBAL_TIME_ZONE_MST = "MST";  // Mountain Standard Time (UTC -7:00)
export const CLONOS_GLOBAL_TIME_ZONE_MDT = "MDT";  // Mountain Daylight Time (UTC -6:00)
export const CLONOS_GLOBAL_TIME_ZONE_CET = "CET";  // Central European Time (UTC +1:00)
export const CLONOS_GLOBAL_TIME_ZONE_CEST = "CEST";  // Central European Summer Time (UTC +2:00)
export const CLONOS_GLOBAL_TIME_ZONE_AEST = "AEST";  // Australian Eastern Standard Time (UTC +10:00)
export const CLONOS_GLOBAL_TIME_ZONE_AEDT = "AEDT";  // Australian Eastern Daylight Time (UTC +11:00)
export const CLONOS_GLOBAL_TIME_ZONE_JST = "JST";  // Japan Standard Time (UTC +9:00)
export const CLONOS_GLOBAL_TIME_ZONE_HKT = "HKT";  // Hong Kong Time (UTC +8:00)
export const CLONOS_GLOBAL_TIME_ZONE_SGT = "SGT";  // Singapore Time (UTC +8:00)
export const CLONOS_GLOBAL_TIME_ZONE_KST = "KST";  // Korea Standard Time (UTC +9:00)
export const CLONOS_GLOBAL_TIME_ZONE_BST = "BST";  // British Summer Time (UTC +1:00)
export const CLONOS_GLOBAL_TIME_ZONE_NZT = "NZT";  // New Zealand Time (UTC +12:00)
export const CLONOS_GLOBAL_TIME_ZONE_NZDT = "NZDT";  // New Zealand Daylight Time (UTC +13:00)
export const CLONOS_GLOBAL_TIME_ZONE_SAST = "SAST";  // South Africa Standard Time (UTC +2:00)
export const CLONOS_GLOBAL_TIME_ZONE_WAT = "WAT";  // West Africa Time (UTC +1:00)
export const CLONOS_GLOBAL_TIME_ZONE_EAT = "EAT";  // East Africa Time (UTC +3:00)
export const CLONOS_GLOBAL_TIME_ZONE_ACST = "ACST";  // Australian Central Standard Time (UTC +9:30)
export const CLONOS_GLOBAL_TIME_ZONE_AWST = "AWST";  // Australian Western Standard Time (UTC +8:00)
export const CLONOS_GLOBAL_TIME_ZONE_NST = "NST";  // Newfoundland Standard Time (UTC -3:30)
export const CLONOS_GLOBAL_TIME_ZONE_AZT = "AZT";  // Azerbaijan Time (UTC +4:00)
export const CLONOS_GLOBAL_TIME_ZONE_ALMT = "ALMT";  // Almaty Time (UTC +6:00)
export const CLONOS_GLOBAL_TIME_ZONE_IRKT = "IRKT";  // Irkutsk Time (UTC +8:00)
export const CLONOS_GLOBAL_TIME_ZONE_YAKT = "YAKT";  // Yakutsk Time (UTC +9:00)
export const CLONOS_GLOBAL_TIME_ZONE_VLAT = "VLAT";  // Vladivostok Time (UTC +10:00)
export const CLONOS_GLOBAL_TIME_ZONE_ISO = "ISO"
