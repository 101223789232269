import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import Styles from './ClonosInput.module.css';
import commonSearchIcon from "../../../assets/UIUX/icons/Common_Icon/COMMON_SEARCH_ICON.svg"
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";
import { globalHandleGetCurrentDateTime } from '../../../utils/clonosCommon';
import { ErrorSVGCom } from '../../../assets/svg/ErrorHandling';
import { CLONOS_GLOBAL_COLOUR_RED, CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, CLONOS_GLOBAL_ERROR_HANDLING_SIZE } from '../../../Constants/Constant';
import useDebounce from '../../../CustomHooks/DebounceHook';

/**
* ClonosInput component for rendering a custom input field.
* @param {Object} props - Component props.
* @property {string} type - Type of the input field.
* @property {string} name - Name attribute for the input field.
* @property {string} placeholder - Placeholder text for the input field.
* @property {string} label - Label for the input field.
* @property {boolean} isLabel - Flag to show/hide the label.
* @property {Object} inputStyle - Additional styles for the input field.
* @property {Object} labelStyle - Additional styles for the label.
* @property {string} uniqueKey - Unique key for the component.
* @property {string} defaultValue - Default value for the input field.
* @property {boolean} isMandatory - Flag indicating whether the field is mandatory.
* @property {function} handleGetValues - Callback function to handle value changes.
* @property {string} areaLabel - Description of the area.
* @property {boolean} isSpecialCharacterAllow - Flag indicating whether special characters are allowed.
* @property {Array} onlyAllowTheseSpecialCharacters - Array of special characters allowed in the input.
* @property {string} errorMessage - Error message to be displayed.
* @property {number} limit - Maximum number of characters allowed in the input field.
* @property {boolean} disabled - Disabled for input tag.
* @property {number} tabIndex - tabIndex for input tag.
* @property {string} icon - Source URL for the icon to be displayed in the input field (used when isSearch is true).
* @property {boolean} isSearch - Indicates whether the input field is a search input. When true, it displays the search icon.
* @property {function} handleGetErrorActivatorInformation - This function will handle all kinds of error handling, that returns one object.
* @property {function} handleGetErrorActivatorInformation.errorActivatorMethod  - This function will trigger or allow showing the error, it takes a boolean value (true/false).
* @property {function} handleGetErrorActivatorInformation.errorSetterMethod  - This function can set the error message, it takes a string.
* @property {function} handleGetErrorActivatorInformation.type  - "type" represents which kind of components it is like "input, select, textarea".
* @property {function} handleGetErrorActivatorInformation.uniqueKey  - "uniqueKey" will be a unique string that represents a specific element.
* @property {function} handleGetErrorActivatorInformation.value  - "value" Initially will be false that you need to change once that specific element has a value and make it true.
* @returns {React.Component} - ClonosInput component.
*/

const ClonosInput = React.memo((
    {
        type = "text",
        uniqueKey,
        value,
        min,
        tabIndex,
        isMinimum = true,
        name,
        placeholder,
        isSpecialCharacterAllow = true,
        onlyAllowTheseSpecialCharacters,
        label,
        errorMessage,
        isLabel,
        containerStyle,
        wrapperClassName,
        isDebounce,
        debounceDelay,
        wrapperStyle,
        inputStyle,
        labelStyle,
        defaultValue,
        isMandatory = false,
        handleGetValues,
        areaLabel,
        limit,
        handleGetErrorActivatorInformation,
        icon,
        onBlur,
        onChange,
        isSearch = false,
        props,
        range,
        disabled = false,
        readOnly = false,
        handleGetTagReference
    }
) => {
    const [lcValue, setLcValue] = useState(null);
    console.log('lcValueeeeeeee:', lcValue)
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [lcErrorMessage, setLcErrorMessage] = useState(errorMessage);
    const [isVisible, setIsVisible] = useState(false);
    const elementReference = useRef(null);
    const inputStringDebounced = useDebounce(lcValue, debounceDelay || 500);

    const getElementReferenceMethod = () => {
        return elementReference;
    }

    const isValidEmail = (email) => {
        // Regular expression for a simple email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        // List of supported email suffixes
        const validSuffixes = [".com", ".in", ".gov"];

        // Check if the email matches the regular expression
        if (emailRegex.test(email)) {
            // Check if the email has a valid suffix
            for (const suffix of validSuffixes) {
                if (email.endsWith(suffix)) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Handle change event for the input field.
     * @param {Object} props - Event object and type of the input field.
     */
    const handleChange = ({ type, e }) => {
        if (e.target.value.length > limit) {
            setShowErrorMessage(true)
            setLcErrorMessage(`Your input should contain less then ${limit} letters!`)
            setLcValue((prev) => {
                let updatedValue = e.target.value.substring(0, limit);
                !isDebounce && handleGetValues && handleGetValues({ type, uniqueKey, updatedValue, name });
                // handleGetValues && handleGetValues({ type, uniqueKey, updatedValue, name });
                return updatedValue
            });
            return
        }
        else setShowErrorMessage(false)
        if (type !== 'file') {
            const value = e.target.value;
            if (range && range?.length > 0) {
                if ((range[0] <= value && e.target.value <= range[1]) || (range[1] <= value && value <= range[0])) {
                    setLcValue(value);
                    setShowErrorMessage(false)
                    setLcErrorMessage(`Please fill ${label ? label?.toLowerCase() : "this"} field!`)
                }
                else {
                    setShowErrorMessage(true)
                    setLcErrorMessage(`You can select ${label} between ${range[0] < range[1] ? `${range[0]} to ${range[1]}` : `${range[1]} to ${range[0]}`} letters!`)
                    setLcValue("")
                    return
                }
            }
            let specialCharacterFlag = isSpecialCharacterAllow;
            const character = value[value?.length - 1];
            const lcSpecialCharacters = "~`!@#$%^&*()_-+=";
            if (isSpecialCharacterAllow === false && lcSpecialCharacters.includes(character)) {
                setLcErrorMessage(`Special characters are not allowed!`)
                setShowErrorMessage(true);
                specialCharacterFlag = false;
            }
            else if (onlyAllowTheseSpecialCharacters?.length > 0 && isSpecialCharacterAllow == true && lcSpecialCharacters.includes(character)) {
                // alert("onlyallowthesespecialCharacter")
                if (onlyAllowTheseSpecialCharacters.includes(character)) {
                    setShowErrorMessage(false);
                    specialCharacterFlag = true;
                } else {
                    setShowErrorMessage(true);
                    specialCharacterFlag = false;
                    setLcErrorMessage(`Only these special character you can enter, ${onlyAllowTheseSpecialCharacters?.join(" ")}`);
                }
            }
            else {
                setLcErrorMessage(errorMessage || `Please fill ${label ? label?.toLowerCase() : "this"} field!`)
                specialCharacterFlag = true
            }
            if (specialCharacterFlag) {
                setLcValue((prev) => {
                    let updatedValue = e.target.value;
                    !isDebounce && handleGetValues && handleGetValues({ type, uniqueKey, updatedValue, name });
                    return updatedValue;
                });
            }
        }
    };

    const currentDate = new Date().toISOString().split('T')[0];
    /**
     * Handle form submission, check for mandatory fields.
     */
    const handleSubmit = () => {
        if (lcValue === null || lcValue?.trim() === '') {
            setShowErrorMessage(true);
            setLcErrorMessage(errorMessage || `Please fill ${label ? label?.toLowerCase() : "this"} field!`)
        } else {
            if (type == "email" && !isValidEmail(lcValue)) {
                setLcErrorMessage("Invalid email address.");
                setShowErrorMessage(true);
                return;
            } else {
                setLcErrorMessage(errorMessage || `Please fill ${label ? label?.toLowerCase() : "this"} field!`);
                setShowErrorMessage(false);
            }
        }
    };

    /**
     * handleReset for reset all initial states
     */

    const handleReset = () => {
        setLcValue("");
        setShowErrorMessage(false);
        setLcErrorMessage(`Please fill ${label ? label?.toLowerCase() : "this"} field!`);
        setIsVisible(false);
    }


    useEffect(() => {
        defaultValue && setLcValue(defaultValue);
        value && setLcValue(value)
        setLcErrorMessage(errorMessage || `Please fill ${label ? label?.toLowerCase() : "this"} field!`)
        if (isMandatory) {
            if (!defaultValue)
                setLcErrorMessage(errorMessage || `Please fill ${label ? label?.toLowerCase() : "this"} field!`)
        }
        handleGetErrorActivatorInformation && handleGetErrorActivatorInformation(
            {
                uniqueKey,
                value: isMandatory ? (typeof (defaultValue) == "string" || typeof (defaultValue) == "number" || (Array.isArray(defaultValue) == "array" || defaultValue?.length > 0 || value?.length)) ? true : false : true,
                type,
                errorActivatorMethod: setShowErrorMessage,
                errorSetterMethod: setLcErrorMessage,
                resetMethod: handleReset,
                getElementReferenceMethod
            }
        )
    }, [defaultValue]);


    useEffect(() => {
        isDebounce && handleGetValues && handleGetValues({ type, uniqueKey, updatedValue: inputStringDebounced, name });
    }, [inputStringDebounced]);

    return (
        <div className={Styles.clonos_input_container} aria-label={areaLabel} style={{ ...containerStyle }}>
            {isLabel && <label style={{ ...labelStyle }}>{label} {isMandatory && <sup className={Styles.clonos_input_isMandatory}>*</sup>}</label>}
            <div
                className={`${Styles.clonos_input} ${wrapperClassName}`}
                style={{
                    ...wrapperStyle, borderColor: showErrorMessage ? CLONOS_GLOBAL_COLOUR_RED : "#8CA1C4",
                    cursor: disabled ? "not-allowed" : "pointer",
                    backgroundColor: disabled ? CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT : "",
                }}
            >
                <input
                    id={uniqueKey}
                    ref={elementReference}
                    tabIndex={tabIndex}
                    type={type == "password" ? isVisible ? "text" : type : type}
                    name={name}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    // value={value ? value : lcValue}
                    value={lcValue !== null ? lcValue : value}
                    defaultValue={defaultValue ? defaultValue : ""}
                    onChange={(e) => {
                        handleChange({ type, e })
                        onChange && onChange(e);
                    }}
                    onBlur={(e) => {
                        isMandatory && handleSubmit();
                        onBlur && onBlur(e)
                    }}
                    min={isMinimum && (type == "text" || type == "date" || type == "time" || type == "datetime-local") ? min?.length > 0 ? min : type == "datetime-local" || type == "time" ? globalHandleGetCurrentDateTime() : currentDate : ""}
                    style={{ ...inputStyle }}
                    {...props}
                />
                {
                    isSearch && <img src={icon ? icon : commonSearchIcon} alt={uniqueKey} />
                }
                {
                    type === "password" && <>{isVisible ? <FaRegEye onClick={() => setIsVisible(!isVisible)} /> : <FaRegEyeSlash onClick={() => setIsVisible(!isVisible)} />}</>
                }
            </div>
            {showErrorMessage && !value && <span style={{ color: CLONOS_GLOBAL_COLOUR_RED }} className={Styles.clonos_input_error}><ErrorSVGCom size={CLONOS_GLOBAL_ERROR_HANDLING_SIZE} color={CLONOS_GLOBAL_COLOUR_RED} /> {lcErrorMessage}</span>}
        </div>
    );
});
export default ClonosInput;