import {
    getChecklistAPI, saveChecklistDataSets, saveChecklistGeneralDetails, getSingleChecklistAPI, saveChecklistAsDraft, getSingleChecklistDataSetsAPI,
    createChecklistFromStaging,
    editChecklistGeneralDetails,
    editChecklistGeneralDetailsAPI,
    changeChecklistStatusAPI,
    getValidateRequestsAPI,
    approveTheValidateRequestAPI,
    createValidateRequestsAPI,
    createSaveAsTemplate,
} from "../../Api/ChecklistAndReports/Checklist";
import { postAuditLog } from "../../Api/User/UserApi";
import { ChecklistAndReportsAction } from "../../Store/Reducers/ClonosChecklistAndReportsReducer";
import { commonActions } from "../../Store/Reducers/CommonReducer";
import {
    exportInformation,
    generateUniqueId,
    getFormatedDate,
    globalManipulateTemplatePreviewData,
    handleLoggedInUser,
    handleLoginExpiry,
    handleServerDateInConfiguredTimeZone,
    handleShowErrorAndWarning,
    setSerialNumber,
    toCamelCase,
} from "../clonosCommon";
import { deleteChecklistAPI } from "../../Api/ChecklistAndReports/Checklist";
import { useDispatch } from "react-redux";
import AppUrl from "../AppUrl";
import { doGetApiCall, doPatchApiCall, doPostApiCall } from "../ClonosApi";
import { LogsAndReportsAction } from "../../Store/Reducers/ClonosLogsReducer";
import { CLONOS_GLOBAL_SERVER_RESPONSE_CODE_BAD_REQUEST_ERROR, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS_1, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS, CLONOS_GLOBAL_STATUS_DRAFT, CLONOS_GLOBAL_TIME_PERIOD_DAY, CLONOS_GLOBAL_TIME_PERIOD_HOUR, CLONOS_GLOBAL_TIME_PERIOD_MONTH, CLONOS_GLOBAL_TIME_PERIOD_WEEK, CLONOS_GLOBAL_TIME_PERIOD_YEAR, CLONOS_GLOBAL_USER_TYPE_OPERATOR } from "../../Constants/Constant";

// import { handleLoggedInUser, handleLoginExpiry, handleShowErrorAndWarning, } from "../clonosCommon";

export const validateChecklistGeneralDetails = (formData, setErrors) => {
    const newErrors = {};
    const currentDate = new Date().toISOString().split("T")[0];
    // Validate Name
    if (!formData?.name) {
        newErrors.name = "Name is required";
    }

    // Validate Asset
    if (!formData?.asset) {
        newErrors.asset = "Asset is required";
    }

    // Validate Start Date
    if (!formData?.startDate) {
        newErrors.startDate = "Start Date is required";
    }

    // Validate End Date
    if (!formData?.endDate) {
        newErrors.endDate = "End Date is required";
    }

    // Validate Start Date
    if (formData?.startDate < currentDate) {
        newErrors.startDate = "Please provide current or future date.";
    }
    // Validate End Date
    if (formData?.endDate < currentDate) {
        newErrors.endDate = "Please provide current or future date.";
    }

    if (formData?.endDate && formData?.startDate) {
        if (formData?.startDate > formData?.endDate) {
            newErrors.startDate = "Start date can not be  greater than End Date.";
            newErrors.endDate = "End date can not be  lesser than Start Date.";
        }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
};

/**
 * To get the selected value from dropdown.
 *
 * @param {object} props - The function's input properties.
 * @property {object} props.value - The returned value from the dropdown.
 * @property {object} props.type - Key name of that particular field.
 * @property {object} props.formData - State to store local changes.
 * @property {object} props.setFormData - Method to update local changes in state.
 */
export const getSelectedValueFromChecklistDropdown = ({
    value,
    type,
    formData,
    setFormData,
}) => {
    if (value === "deselected") {
        delete formData[type];
        setFormData({ ...formData });
    } else {
        setFormData((prev) => ({
            ...prev,
            [type]: { name: value?.label, id: value?.value },
        }));
    }
};
/**
 * To handle the value of repeatation number based on frequency change
 * @property {object} props.count - Input value for repeatation number
 * @property {object} props.formData - State to store local changes.
 * @property {object} props.setFormData - Method to update local changes in state.
 */
export const handleRepeatationNumber = ({ count, formData, setFormData }) => {
    let numAsString = count.toString();
    if (numAsString?.length > 2) {
        let firstTwoDigits = numAsString.slice(0, 2);
        count = parseInt(firstTwoDigits, 10);
    }
    if (formData?.frequencyType?.name) {
        if (count === 1) {
            if (
                formData?.frequency?.name === "Hourly" &&
                formData?.frequencyPeriod < 23
            ) {
                setFormData((prev) => ({
                    ...prev,
                    frequencyPeriod: (Number(formData?.frequencyPeriod) || 0) + count,
                }));
            } else {
                setFormData((prev) => ({
                    ...prev,
                    frequencyPeriod: (Number(formData?.frequencyPeriod) || 0) + count,
                }));
            }
        } else if (count === -1) {
            if (formData?.frequencyPeriod) {
                setFormData((prev) => ({
                    ...prev,
                    frequencyPeriod: Number(formData?.frequencyPeriod) + count,
                }));
            }
        } else {
            if (Number(count) >= 0) {
                if (formData?.frequency?.name === "Hourly" && count > 23) {
                    setFormData((prev) => ({
                        ...prev,
                        frequencyPeriod: 23,
                    }));
                } else {
                    setFormData((prev) => ({
                        ...prev,
                        frequencyPeriod: count,
                    }));
                }
            }
        }
    }
};
/**
 * To get Repeatation duration based on frequency change
 * @property {object} props.formData - State to store local changes.
 */
export const showRepeatationFrequency = (formData) => {
    if (formData?.frequency?.name === "Hourly") {
        return "Hours";
    } else if (formData?.frequency?.name === "Daily") {
        return "Days";
    } else if (formData?.frequency?.name === "Weekly") {
        return "Weeks";
    } else if (formData?.frequency?.name === "Monthly") {
        return "Month";
    } else if (formData?.frequency?.name === "Quarterly") {
        return "Months";
    } else if (formData?.frequency?.name === "Half-yearly") {
        return "Months";
    } else if (formData?.frequency?.name === "Annualy") {
        return "Years";
    }
    return "";
};

// ********************* TEMPLATE METHODS *********************

export const handleReturnedFieldValue = ({
    id,
    fieldName,
    fieldValue,
    dispatch,
    attributes,
    asset,
    handleGetValues
}) => {
    let updatedAttributes = attributes?.map((attribute) => {
        if (attribute?.id === id) {
            return {
                ...attribute,
                fieldName,
                fieldValue,
                asset,
            };
        } else {
            return attribute;
        }
    });
    handleGetValues({ updatedValue: updatedAttributes, isTyping: true });
    dispatch(
        ChecklistAndReportsAction.setSelectedAttributesToolkitState(
            updatedAttributes
        )
    );
};

//  *********************  METHODS TO VALIDATE UPLOADED FILES  *********************
/**
 * Checks if the number of uploaded files exceeds a specified limit.
 *
 * @param {object} props - The function's input properties.
 * @param {number} props.limit - The maximum number of allowed files.
 * @param {array} props.files - The list of uploaded files.
 * @returns {boolean} - Returns true if the number of files is within the limit, or false if it exceeds the limit.
 */
export const validateFilesLimit = ({ limit, files, dispatch }) => {
    if (files.length > limit) {
        handleShowErrorAndWarning({
            dispatch,
            type: "error",
            showTime: 20000,
            message: `You can't upload more than ${limit} files.`,
        });
        return false;
    }
    // if (defaultValue && defaultValue.length + files.length > limit) {
    //     handleShowErrorAndWarning({ dispatch, type: "error", showTime: 20000, message: `You have already uploaded ${defaultValue.length} file, So you can only upload ${limit - defaultValue.length} files more, because the limit is ${limit}!.` });
    //     return false
    // }
    return true;
};

/**
 * Checks if files need to be uploaded are not already present in the list of uploaded files.
 *
 * @param {object} props - The function's input properties.
 * @param {array} props.needToUploadedFiles - The files that need to be uploaded.
 * @param {array} props.uploadedFiles - The list of already uploaded files.
 * @returns {array} - Returns a modified list of files with unique items.
 */
export const handleCheckFileIsPresentOrNot = ({
    needToUploadedFiles,
    uploadedFiles,
    limit,
}) => {
    let uploadedFilesLc = uploadedFiles?.map((ele) => ele.name);
    let needToUploadedFilesLc = needToUploadedFiles.filter(
        (item) => !uploadedFilesLc?.includes(item.name)
    );
    const newFilesNeedToUpload = needToUploadedFilesLc;
    needToUploadedFilesLc = [...needToUploadedFilesLc, ...uploadedFiles || []];
    console.log('needToUploadedFilesLc:', needToUploadedFilesLc)
    return { totalFiles: needToUploadedFilesLc, newFiles: newFilesNeedToUpload };
};

/**
 * Checks if the file types of the uploaded files are valid.
 *
 * @param {object} props - The function's input properties.
 * @param {array} props.files - The list of uploaded files.
 * @param {array} props.acceptableFileTypes - The list of supported file types.
 * @returns {boolean} - Returns true if all file types are valid, or false if any file type is unsupported.
 */
export const handleValidateFilesType = ({
    files,
    acceptableFileTypes,
    dispatch,
}) => {
    let fileNames = files.map((item) => item.name);
    let count = 0;
    for (let i = 0; i < fileNames.length; i++) {
        let tempElement = fileNames[i].split(".");
        if (
            acceptableFileTypes.includes(`.${[tempElement[tempElement.length - 1]]}`)
        )
            count++;
    }
    if (count === fileNames.length) return true;
    else {
        handleShowErrorAndWarning({
            dispatch,
            type: "error",
            message: "Please upload supported file types!",
            showTime: 5000,
        });
        return false;
    }
};

/**
 * Checks if the size of each uploaded file is within the specified limit.
 *
 * @param {object} props - The function's input properties.
 * @param {array} props.files - The list of uploaded files to check.
 * @param {number} props.fileSizeInMB - The maximum file size allowed in megabytes.
 * @returns {boolean} - Returns true if all file sizes are within the limit, or false if any file size exceeds the limit.
 */
export const handleValidateFileSize = ({ files, fileSizeInMB, dispatch }) => {
    files.forEach((file) => {
        let sizeInBytes = fileSizeInMB * 1024 * 1024;
        if (file.size > sizeInBytes) {
            // Notify that the file size is greater (consider using a more user-friendly notification)
            handleShowErrorAndWarning({
                dispatch,
                type: "error",
                message: `File size should be less than ${fileSizeInMB}MB!`,
                showTime: 15000,
            });
            return false; // Return false if a file size exceeds the limit
        }
    });
    return true; // Return true if all file sizes are within the limit
};

/*
 To show linear progress while uploading file
*/

export const handleLinearProgress = ({ files, setUploadProgress }) => {
    let tempProgress = {};
    files?.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);

        // Simulate a file upload progress
        let progress = 0;
        const interval = setInterval(() => {
            if (progress < 100) {
                progress += 10;
                setUploadProgress((prevProgress) => {
                    tempProgress = {
                        ...prevProgress,
                        [file.name]: progress,
                    };
                    return tempProgress;
                });
            } else {
                clearInterval(interval);
            }
        }, 200);
    });
};

/*
To validate start date & end date while creation of checklist general details.
*/
export const validateStartAndEndDate = ({
    formData,
    setFormData,
    dispatch,
}) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (formData?.startDate < currentDate) {
        dispatch(
            commonActions.handleSnackbar({
                show: true,
                message: "Please provide current or future date",
                type: "error",
            })
        );
        // setFormData((prev) => ({ ...prev, startDate: "" }))
    }
    if (formData?.endDate < currentDate) {
        dispatch(
            commonActions.handleSnackbar({
                show: true,
                message: "Please provide current or future date",
                type: "error",
            })
        );
        // setFormData((prev) => ({ ...prev, endDate: "" }))
    }
    if (formData?.startDate > formData?.endDate) {
        if (formData?.startDate >= formData?.endDate) {
            dispatch(
                commonActions.handleSnackbar({
                    show: true,
                    message: "Please provide valid start and end date",
                    type: "error",
                })
            );
            //   setFormData((prev) => ({...prev,startDate:"",endDate:""}))
        }
    }
};

// CHECKLIST CRUD

/**
 * Handles the retrieval of checklist data and filters it based on the logged-in user.
 * @param {Object} options - Options object containing dispatch and responseSetterMethod.
 */
export const handleGetChecklist = async ({
    dispatch,
    responseSetterMethod,
    payload,
    totalRecordSetter,
    loadingSetter
}) => {

    loadingSetter(true)
    doGetApiCall({ url: AppUrl?.getChecklists, params: payload }).then(response => {
        console.log('response:', response)
        const result = {
            status: response?.status,
            data: response?.data?.result?.data,
            message: response?.data?.message || response?.response?.data?.message,
            pagination: {
                currentPage: response?.data?.result?.currentPage,
                totalPageCount: response?.data?.result?.totalPageCount,
                totalDataCount: response?.data?.result?.totalDataCount,
            }
        }
        console.log('result:', result)

        // Check if the request was successful (status 200 or 201).
        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            // Extract checklist items from the response.
            result.data = (result?.data || []).map((item) => {
                return {
                    ...item?.generalDetails,
                    templateStatuses: item?.templateStatuses ? item?.templateStatuses : [],
                }
            })
            totalRecordSetter(result?.pagination?.totalDataCount)
            console.log('result:', result)
            const records = setSerialNumber(result?.data, payload?.page, payload?.limit)
            responseSetterMethod(records);
        }
    }).finally(() => {
        loadingSetter(false)
    })
};

// Delete Checklists

/**
 * Handles the deletion of checklist items through the API and updates the local checklist dataset.
 * @param {Object} options - Options object containing the necessary parameters.
 * @param {function} options.dispatch - The dispatch function from a React context or Redux store for handling state updates.
 * @param {Array} options.selectedRows - An array of checklist item IDs selected for deletion.
 * @param {Boolean} options.isLocalUpdate - Based on "isLocalUpdate" key we can control that we want to do local DOM update or not
 * @param {function} options.lcDataSetterMethod - A function responsible for updating the local checklist dataset after deletion.
 *        This function should take the previous state as an argument and return the updated state based on the deleted items.
 */
export const handleDeleteChecklist = async ({
    dispatch,
    selectedRows,
    lcDataSetterMethod,
    isLocalUpdate,
    responseSetterMethod,
}) => {
    try {
        // Send API request to delete selected checklist items.
        const response = await deleteChecklistAPI({ checklistIds: selectedRows });
        // Check if the deletion request was successful (status 200 or 201).
        if (response.status == 200 || response.status == 201) {
            // Based on "isLocalUpdate" key we can control that we want to do local DOM update or not
            if (isLocalUpdate) {
                // Update the local checklist dataset based on the deleted items.
                lcDataSetterMethod((prev) => {
                    let filteredData = null;
                    if (selectedRows.length == 1) {
                        // If only one item is deleted, filter it out.
                        filteredData = prev.filter((item) => item.id != selectedRows[0]);
                        return filteredData;
                    } else {
                        // If multiple items are deleted, filter them out.
                        filteredData = prev.filter(
                            (item) => !selectedRows.includes(item.id)
                        );
                        return filteredData;
                    }
                });
            } else {
                // Calling for getting updated data from the server.
                handleGetChecklist({
                    dispatch,
                    responseSetterMethod: lcDataSetterMethod,
                });
            }
            handleShowErrorAndWarning({
                dispatch,
                type: "success",
                message: `Checklist has been deleted.`,
                showTime: 5000,
            }); // for show the message.
            return response; // here we are returning the status of the response and based on that some operation will heppen.
        }
    } catch (err) {
        // Handle errors, including login expiration and display error messages.
        handleLoginExpiry(err, dispatch);

        // Check if the error status is not 401 (Unauthorized).
        if (err?.response?.data?.status !== 401) {
            // Show an error message with the specific error details.
            handleShowErrorAndWarning({
                dispatch,
                type: "error",
                message: `${err.response.data.message}`,
                showTime: 5000,
            });

            // Log the error in the audit log.
            const error = err.response.data.error;
            postAuditLog({ action: "Checklist Deleted", message: error });
        }
    }
    finally {
        responseSetterMethod && responseSetterMethod?.setLoading(false);
        responseSetterMethod && responseSetterMethod?.setShowConfirmationDailog(false);
        responseSetterMethod && responseSetterMethod?.setRefreshApi();
        responseSetterMethod && responseSetterMethod?.setRefreshStatusCount()
    }
};

// Edit Checklists

export const handleEditChecklistGeneralDetails = ({
    dispatch,
    payload,
    checklistId,
    lcStateSetterMethod,
    checklistStatus,
    Navigate,
}) => {
    lcStateSetterMethod(prev => {
        return { ...prev, "editGeneralDetailsLoading": true }
    })

    const recurrenceReqeustBody = handleGenerateRecurreceRequestBody(payload);
    let lcTimePeriodRecurrence = recurrenceReqeustBody?.lcTimePeriodRecurrence;
    let lcUserSpecificCustomization = recurrenceReqeustBody?.lcUserSpecificCustomization;
    let scheduledReportDetails = recurrenceReqeustBody?.scheduledReportDetails;
    console.log('scheduledReportDetails:', scheduledReportDetails)

    let newPayload = {};  // Local Payload
    payload?.name && (newPayload.name = payload?.name);
    payload?.description && (newPayload.description = payload?.description);
    payload?.documentNumber && (newPayload.documentNumber = payload?.documentNumber ? payload?.documentNumber : "");
    payload?.asset && (newPayload.assetId = payload?.asset[0]?.id);
    payload?.departments && (newPayload.departments = (payload?.departments || []).map(item => item?.id));
    payload?.teams && (newPayload.teams = (payload?.teams || []).map(item => item?.id));
    payload?.assignees && (newPayload.assignees = (payload?.assignees || []).map(item => item?.id));
    payload?.startDate && (newPayload.startDateAndTime = handleServerDateInConfiguredTimeZone({ dateString: payload?.startDate }));
    payload?.endDate && (newPayload.endDateAndTime = handleServerDateInConfiguredTimeZone({ dateString: payload?.endDate }));
    payload?.isRecurrence && (newPayload.isRecurrence = payload?.isRecurrence);
    payload?.isScheduleReport && (newPayload.scheduledReportDetails = scheduledReportDetails)
    newPayload.isActive = true;

    if (checklistStatus === CLONOS_GLOBAL_STATUS_DRAFT) {
        // newPayload.isDraft = false
        payload?.isScheduleReport && (newPayload.isScheduleReport = payload?.isScheduleReport);
        payload?.isStaticGeneration && (newPayload.isStaticGeneration = payload?.isStaticGeneration);
        newPayload.recurrenceDetails = Object.keys(lcTimePeriodRecurrence).length ? lcTimePeriodRecurrence : null;
        lcUserSpecificCustomization?.length && (newPayload.userSpecificDetails = lcUserSpecificCustomization);
        if (Object.keys(lcTimePeriodRecurrence)?.length && payload?.isRecurrence) {
            newPayload.isRecurrence = payload?.isRecurrence;
        };
    };



    doPatchApiCall({ url: `/checklists/${checklistId}`, payload: newPayload }).then((response) => {
        const result = {
            status: response?.status,
            message: response?.data?.message
        };

        if (result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS) {
            handleShowErrorAndWarning({ dispatch, type: "success", message: `Checklist General Details Edited`, showTime: 5000 });
            lcStateSetterMethod(prev => {
                return { ...prev, "isChecklistEdited": true, isChangesMade: false }
            });
            if (checklistStatus === CLONOS_GLOBAL_STATUS_DRAFT) {
                dispatch(ChecklistAndReportsAction.setChecklistGeneralDetailsCreationDetails(
                    {
                        id: checklistId,
                        name: payload?.name || "",
                        description: payload?.description || ""
                    })
                );
                Navigate("/create-checklist-template");
            }
        };
    }).finally(() => {
        lcStateSetterMethod(prev => {
            return { ...prev, "editGeneralDetailsLoading": false }
        });
    });
};

// Get Single Checklist Details

export const handleGetSingleChecklist = ({
    responseSetterMethod,
    checklistId,
    lcStateSetterMethod,
}) => {
    lcStateSetterMethod(prev => {
        console.log('prev:', prev)
        return { ...prev, "generalDetailsLoading": true }
    });
    doGetApiCall({ url: `${AppUrl?.getSingleChecklistDetails}/${checklistId}` }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status,
            data: { ...response?.data?.result },
            message: response?.data?.message,
        };
        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            console.log('result:', result)
            // Extract checklist items from the response.
            responseSetterMethod(prev => {
                return { ...prev, ["generalDetails"]: result?.data };
            });
        };
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, "generalDetailsLoading": false }));
    });
};
// Get Recurred Checklist Entries

export const handleGetRecurredChecklistEntries = async ({
    responseSetterMethod,
    checklistId,
    lcStateSetterMethod,
    pagination
}) => {
    lcStateSetterMethod(prev => {
        console.log('prev:', prev)
        return { ...prev, "entriesLoading": true }
    });
    doGetApiCall({ url: `/checklists/${checklistId}/entries`, params: pagination }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status,
            data: response?.data?.result?.data,
            message: response?.data?.message,
            pagination: {
                currentPage: response?.data?.result?.currentPage,
                totalPageCount: response?.data?.result?.totalPageCount,
                totalDataCount: response?.data?.result?.totalDataCount,
            }
        };
        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            lcStateSetterMethod(prev => ({ ...prev, "entriesLoading": false, totalDataCount: result?.pagination?.totalDataCount }));
            // Extract checklist items from the response.
            responseSetterMethod(prev => {
                return { ...prev, ["recurredChecklistEntries"]: setSerialNumber(result?.data, pagination?.page, pagination?.limit) };
            });
        };
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, "entriesLoading": false }));
    });
};

export const handleGetTemplateVersions = async ({
    responseSetterMethod,
    checklistId,
    lcStateSetterMethod,
    pagination
}) => {
    lcStateSetterMethod(prev => {
        console.log('prev:', prev)
        return { ...prev, "versionsLoading": true }
    });
    doGetApiCall({ url: `/checklists/${checklistId}/versions`, params: pagination }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status,
            data: response?.data?.result?.data,
            message: response?.data?.message,
            pagination: {
                currentPage: response?.data?.result?.currentPage,
                totalPageCount: response?.data?.result?.totalPageCount,
                totalDataCount: response?.data?.result?.totalDataCount,
            }
        };
        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            lcStateSetterMethod(prev => ({ ...prev, "versionsLoading": false, totalDataCount: result?.pagination?.totalDataCount }));
            // Extract checklist items from the response.
            responseSetterMethod(prev => {
                return { ...prev, ["templateVersions"]: setSerialNumber(result?.data, pagination?.page, pagination?.limit) };
            });
        };
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, "versionsLoading": false }));
    });
};

// Get Single Checklist DetaSets Details

export const handleGetSingleChecklistDetaSets = async ({ dispatch, responseSetterMethod, checklistId }) => {
    try {
        const response = await getSingleChecklistDataSetsAPI({ checklistId, optionalParams: { images: 1, fieldValue: 1 } })

        // Check if the request was successful (status 200 or 201).
        if (response.status == 200 || response.status == 201) {
            // Extract checklist items from the response.
            const data = response?.data?.result
            const updatedDataSets = data?.data?.map((item, index) => {
                return { ...item, "index": index + 1 }
            })
            responseSetterMethod(prev => {
                return { ...prev, ["singleChecklistDataSetsDetails"]: updatedDataSets?.sort((a, b) => Number(a["index"]) - Number(b["index"])) }
            })
        }
    }
    catch (err) {
        // Handle errors, including login expiration and display error messages.
        handleLoginExpiry(err, dispatch)

        // Check if the error status is not 401 (Unauthorized).
        if (err?.response?.data?.status !== 401) {
            // Show an error message with the specific error details.
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${err.message}`, showTime: 5000 })
            // Log the error in the audit log.
            postAuditLog({ action: "Get Single Checklist Data Sets", message: "Server Error with status code 400" });
        }
    }
}


// Get Single Version details and attributes details.

export const handleGetVersionTemplateDataSets = async ({
    responseSetterMethod,
    templateId,
    lcStateSetterMethod,
}) => {
    lcStateSetterMethod(prev => {
        return { ...prev, "versionTemplatePreviewLoading": true }
    });

    doGetApiCall({ url: `${AppUrl?.getVersionTemplateDataSets}/${templateId}` }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status,
            data: response?.data?.result[0],
            message: response?.data?.message,
        };
        console.log('result:', result)
        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            // Extract checklist items from the response.
            lcStateSetterMethod(prev => ({ ...prev, ["manipulatedDataForVersionTemplatePreview"]: globalManipulateTemplatePreviewData({ data: result?.data?.dataSets }) }))
            responseSetterMethod(prev => {
                return { ...prev, ["versionTemplateDetails"]: result?.data };
            });
        };
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, "versionTemplatePreviewLoading": false }));
    });
};


// Get Single Version details and attributes details.

export const handleGetChecklistStructure = async ({
    responseSetterMethod,
    structureId,
    lcStateSetterMethod,
    dispatch
}) => {

    lcStateSetterMethod(prev => {
        return { ...prev, "getChecklistStructureLoading": true }
    });

    doGetApiCall({ url: `${AppUrl?.getChecklistStructure}/${structureId}` }).then((response) => {
        console.log('responsessss:', response)
        const result = {
            status: response?.status,
            data: response?.data?.result[0],
            message: response?.data?.message,
        };
        console.log('result:', result)
        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            // Extract checklist items from the response.
            const modifiedDataSets = globalManipulateTemplatePreviewData({ data: result?.data?.dataSets });
            console.log('modifiedDataSets:', modifiedDataSets)
            // lcStateSetterMethod(prev => ({ ...prev, ["structureDataSets"]: modifiedDataSets }));
            dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState(modifiedDataSets));
            dispatch(ChecklistAndReportsAction.setTemplateInfoToolkitState({
                "uploadedFiles": [],
                "noteDetails": result?.data?.note
            }));

            responseSetterMethod(prev => {
                return { ...prev, ["checklistStructureDetails"]: result?.data };
            });
        };
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, "getChecklistStructureLoading": false }));
    });
};

export const handleUpdateGeneralTemplate = async ({
    responseSetterMethod,
    generalTemplateId,
    lcStateSetterMethod,
    dispatch,
    payload,
    Navigate
}) => {

    lcStateSetterMethod(prev => {
        return { ...prev, "generalTemplateLoading": true }
    });

    // Checking the if any attribute name are same or not?
    let hasMap = {};
    let isAllAttributeNamesAreUnique = true;
    payload && payload?.dataSets?.length && payload?.dataSets?.forEach((item, index) => {
        if (isAllAttributeNamesAreUnique) {
            const key = item?.fieldName.trim().toString()
            if (hasMap[key] || key === "") {
                let message = null;
                message = `SI.NO ${index + 1} : "${item?.fieldName}" attribute is already present, please change the name!.`
                handleShowErrorAndWarning({ dispatch, type: "error", message, showTime: 5000 });
                isAllAttributeNamesAreUnique = false
            }
            else {
                hasMap[key] = 1;
            };
        }
    });

    if (!isAllAttributeNamesAreUnique) {
        lcStateSetterMethod((prev) => {
            return { ...prev, ["generalTemplateLoading"]: false }
        })
        return; // flag for restrict if the duplicate attribute name found.
    }

    doPatchApiCall({ url: `${AppUrl?.updateGeneralTemplate}/${generalTemplateId}`, payload }).then((response) => {
        console.log('responsessss:', response)
        const result = {
            status: response?.status,
            data: response?.data?.result[0],
            message: response?.data?.message,
        };
        console.log('result:', result)

    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, "generalTemplateLoading": false }));
        Navigate(-1);
    });
};


export const handleGetTemplateDataSets = ({
    templateId,
    lcStateSetterMethod,
    dispatch,
    isPreview
}) => {

    doGetApiCall({ url: `${AppUrl.getTemplateDetails}${templateId}` })
        .then(response => {
            console.log('response:', response)
            lcStateSetterMethod(prev => {
                let data = globalManipulateTemplatePreviewData({ data: response?.data?.result?.dataSets });
                console.log('data:', data)

                if (!isPreview) {
                    dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState(data));
                }
                return { ...prev, ["manipulatedDataForPreviewChecklistAttributes"]: data };
            });
        }).finally(() => {
            lcStateSetterMethod(prev => {
                return { ...prev, ["getTemplateDataSetsLoading"]: false };
            });
        });
}

export const handleGetChecklistEntry = async ({
    responseSetterMethod,
    entryId,
    lcStateSetterMethod,
}) => {

    lcStateSetterMethod(prev => {
        return { ...prev, "checklistEntryDetailsLoading": true }
    });
    doGetApiCall({ url: `${AppUrl?.getEntryDetails}/${entryId}` }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status,
            data: response?.data?.result,
            message: response?.data?.message,
        };
        console.log('result:', result)
        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            lcStateSetterMethod(prev => ({ ...prev, ["manipulatedDataForTemplatePreview"]: globalManipulateTemplatePreviewData({ data: result?.data?.checklistEntry?.data }) }))
            responseSetterMethod(prev => {
                return { ...prev, ["entryDetails"]: result?.data, ["lcChecklistData"]: result?.data?.checklistDetails };
            });
        };
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, "checklistEntryDetailsLoading": false }));
    });
};

// export const handleGetStructure = async ({
//     responseSetterMethod,
//     structureId,
//     lcStateSetterMethod,
// }) => {

//     lcStateSetterMethod(prev => {
//         return { ...prev, "structureDetailsLoading": true }
//     });
//     doGetApiCall({ url: `${AppUrl?.getEntryDetails}/${structureId}` }).then((response) => {
//         console.log('response:', response)
//         const result = {
//             status: response?.status,
//             data: response?.data?.result,
//             message: response?.data?.message,
//         };

//         if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
//             // lcStateSetterMethod(prev => ({ ...prev, ["manipulatedDataForTemplatePreview"]: globalManipulateTemplatePreviewData({ data: result?.data?.ChecklistEntry?.data }) }))
//             responseSetterMethod(prev => {
//                 return { ...prev, ["structureDetails"]: result?.data };
//             });
//         };
//     }).finally(() => {
//         lcStateSetterMethod(prev => ({ ...prev, "checklistEntryDetailsLoading": false }));
//     });
// };

// export checklist information

export const exportChecklistInformaiton = ({
    type,
    fileName,
    pdfData,
    xlsxData,
    pageType,
}) => {
    if (type == "pdf")
        exportInformation({ data: pdfData, type, fileName, pageType });
    else if (type == "xlsx") {
        exportInformation({ data: [xlsxData], type, fileName });
    }
};


const handleGenerateRecurreceRequestBody = (payload) => {
    const { timePeriod, basedOnTimePeriodRecurrence, userSpecificCustomization } = payload;
    let lcTimePeriodRecurrence = {};
    let lcUserSpecificCustomization = null;
    let scheduledReportDetails = {};

    if (timePeriod === CLONOS_GLOBAL_TIME_PERIOD_HOUR || timePeriod === CLONOS_GLOBAL_TIME_PERIOD_DAY || timePeriod === CLONOS_GLOBAL_TIME_PERIOD_WEEK) {
        const { occursOnSpecificWeekDay, recurOn } = basedOnTimePeriodRecurrence[timePeriod];
        if (occursOnSpecificWeekDay && recurOn?.value === "custom") {
            lcTimePeriodRecurrence['occurrDays'] = (occursOnSpecificWeekDay || []).map(item => item.uniqueKey);
        };
        if (recurOn) {
            lcTimePeriodRecurrence['recurrOn'] = recurOn?.value
        };
        if (!recurOn && timePeriod === CLONOS_GLOBAL_TIME_PERIOD_WEEK) {
            lcTimePeriodRecurrence["recurrOn"] = "custom";
        }

        // User specific Customization.
        if (userSpecificCustomization) {
            let usersData = [];
            for (let key in userSpecificCustomization) {
                const updatedData = userSpecificCustomization[key][`${timePeriod}Recurrence`]?.map(item => {
                    return {
                        occurDays: item?.occursOnSpecificWeekDay,
                        shiftTiming: {
                            ["type"]: item?.shiftTiming?.label,
                            ["from"]: item?.shiftTiming?.value?.split("to")[0],
                            ["to"]: item?.shiftTiming?.value?.split("to")[1],
                        }
                    }
                })
                usersData.push(
                    {
                        operatorId: key,
                        recurrenceDetails: updatedData
                    }
                )
            }
            console.log('usersData:', usersData)
            lcUserSpecificCustomization = usersData;
        }
    }
    else if (timePeriod === CLONOS_GLOBAL_TIME_PERIOD_MONTH) {
        const { specificDays } = basedOnTimePeriodRecurrence[timePeriod];
        if (specificDays) {
            const occurenceTypeCase = {
                "first": "1",
                "second": "2",
                "third": "3",
                "fourth": "4",
                "fifth": "5"
            }
            const splittedData = specificDays?.split("_");
            const type = splittedData[0];
            if (type === "monthlyWeekday") {
                lcTimePeriodRecurrence["specificDay"] = { type, weekDay: splittedData[3], occurrence: occurenceTypeCase[splittedData[2]] }
            } else if (type === "monthlyDay") {
                lcTimePeriodRecurrence["specificDay"] = { type, day: splittedData[2] }
            } else {
                lcTimePeriodRecurrence["specificDate"] = { type, specificDate: getFormatedDate(splittedData[0]) }
            }
        }

        if (userSpecificCustomization) {
            let usersData = [];
            for (let key in userSpecificCustomization) {
                const updatedData = userSpecificCustomization[key][`${timePeriod}Recurrence`]?.map(item => {
                    return {
                        operatorId: key,
                        shiftTiming: {
                            ["type"]: item?.shiftTiming?.label,
                            ["from"]: item?.shiftTiming?.value?.split("to")[0],
                            ["to"]: item?.shiftTiming?.value?.split("to")[1],
                        }
                    }
                })
                usersData.push(updatedData)
            }
            console.log('usersData:', usersData)
            lcUserSpecificCustomization = usersData;
        }
    }
    if (payload?.isRecurrence) {
        lcTimePeriodRecurrence['frequency'] = (payload?.inEach ? payload?.inEach : 1);
        lcTimePeriodRecurrence['timePeriod'] = (payload?.timePeriod ? payload?.timePeriod?.toLowerCase() : null);
    }
    if (payload?.isScheduleReport) {
        scheduledReportDetails["scheduleTime"] = `${payload?.scheduleTime?.hour?.length === 1 ? `0${payload?.scheduleTime?.hour}` : payload?.scheduleTime?.hour}:${payload?.scheduleTime?.minute?.length === 1 ? `0${payload?.scheduleTime?.minute}` : payload?.scheduleTime?.minute}`;
        scheduledReportDetails["approver"] = payload?.approver?.[0]?.id;
        scheduledReportDetails["frequency"] = payload?.scheduleReportInEach ? payload?.scheduleReportInEach : 1;
        scheduledReportDetails["timePeriod"] = payload?.scheduleReportTimePeriod?.toLowerCase();
        if (payload?.scheduleReportTimePeriod === CLONOS_GLOBAL_TIME_PERIOD_DAY) {
            scheduledReportDetails["recurrOn"] = payload?.basedOnScheduleReportTimePeriodRecurrence?.[payload?.scheduleReportTimePeriod]?.recurOn?.value;
        } else if (payload?.scheduleReportTimePeriod === CLONOS_GLOBAL_TIME_PERIOD_MONTH) {
            scheduledReportDetails["specificDate"] = payload?.startDate;
        };
    }

    return { lcTimePeriodRecurrence, lcUserSpecificCustomization, scheduledReportDetails }
}

/**
 * To save checklist general details..
 * @param {Object} payload - genaral details of checklist.
 */
export const handleSaveChecklistGeneralDetails = async ({ payload, dispatch, isDraft, Navigate, responseSetterMethod }) => {

    const recurrenceReqeustBody = handleGenerateRecurreceRequestBody(payload);
    let lcTimePeriodRecurrence = recurrenceReqeustBody?.lcTimePeriodRecurrence;
    let lcUserSpecificCustomization = recurrenceReqeustBody?.lcUserSpecificCustomization;
    let scheduledReportDetails = recurrenceReqeustBody?.scheduledReportDetails;


    console.log('lcTimePeriodRecurrence:', lcTimePeriodRecurrence)
    console.log('lcUserSpecificCustomization:', lcUserSpecificCustomization)


    // Loading handling section 
    if (isDraft) {
        responseSetterMethod((prev) => {
            return { ...prev, ["savingLogsGeneralDetailsAsDraftLoading"]: true }
        })
    }
    else {
        responseSetterMethod((prev) => {
            return { ...prev, ["checklistGeneralDetailsLoading"]: true }
        })
    }


    let newPayload = {};  // Local Payload
    payload?.name && (newPayload.name = payload?.name);
    payload?.description && (newPayload.description = payload?.description);
    newPayload.documentNumber = payload?.documentNumber ? payload?.documentNumber : "";
    payload?.asset && (newPayload.assetId = payload?.asset[0]?.id);
    payload?.departments && (newPayload.departments = (payload?.departments || []).map(item => item?.id));
    payload?.teams && (newPayload.teams = (payload?.teams || []).map(item => item?.id));
    payload?.assignees && (newPayload.assignees = (payload?.assignees || []).map(item => item?.id));
    payload?.startDate && (newPayload.startDateAndTime = handleServerDateInConfiguredTimeZone({ dateString: payload?.startDate }));
    payload?.endDate && (newPayload.endDateAndTime = handleServerDateInConfiguredTimeZone({ dateString: payload?.endDate }));
    newPayload.isRecurrence = false;
    newPayload.isActive = true;
    payload?.isScheduleReport && (newPayload.isScheduleReport = payload?.isScheduleReport);
    payload?.isStaticGeneration && (newPayload.isStaticGeneration = payload?.isStaticGeneration);
    newPayload.recurrenceDetails = Object.keys(lcTimePeriodRecurrence).length ? lcTimePeriodRecurrence : null;
    lcUserSpecificCustomization?.length && (newPayload.userSpecificDetails = lcUserSpecificCustomization);
    payload?.isScheduleReport && (newPayload.scheduledReportDetails = scheduledReportDetails)

    if (Object.keys(lcTimePeriodRecurrence)?.length && payload?.isRecurrence) {
        newPayload.isRecurrence = payload?.isRecurrence;
    }
    console.log('newPayload:', newPayload)
    doPostApiCall({ url: `${AppUrl.postChecklistGeneralDetails}`, payload: newPayload, queryParams: { isDraft } }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response.status || response?.response?.status,
            errorInfo: response?.response?.data?.message
        }

        // Check the API response status and show appropriate notifications.
        if (result.status == CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS) {
            dispatch(ChecklistAndReportsAction.setChecklistGeneralDetailsCreationDetails(
                {
                    id: response?.data?.result?.checklistId,
                    name: payload?.name,
                    description: payload?.description
                })
            );
            if (isDraft) {
                Navigate("/checklists");
            } else {
                Navigate("/create-checklist-template");
            }
            // Navigate("/create-checklist-template", { state: { generalDetails: { id: response?.data?.result?.id, name: payload?.name, description: payload?.description } } })
        } else {
            dispatch(commonActions.handleSnackbar({
                show: true,
                message: `${result?.errorInfo}!.`,
                type: "error",
            }));
        }
    }).finally(() => {
        if (isDraft) {
            responseSetterMethod((prev) => {
                return { ...prev, ["savingLogsGeneralDetailsAsDraftLoading"]: false }
            })
        }
        else {
            responseSetterMethod((prev) => {
                return { ...prev, ["checklistGeneralDetailsLoading"]: false }
            })
        }
    })
}


/*
TO SAVE CHECKLIST AS TEMPLATE
*/

export const handleSaveChecklistDataSetsMethod = ({
    checklistId,
    payload,
    dispatch,
    Navigate,
    isGeneralTemplate,
    responseSetterMethod,
    isDuplicateTemplateSetterMethod
}) => {
    if (isGeneralTemplate) {
        if (payload?.dataSets?.length == 0) {
            handleShowErrorAndWarning({ dispatch, type: "error", message: `Please add some attributes or improt from library.`, showTime: 5000 });
            isDuplicateTemplateSetterMethod && isDuplicateTemplateSetterMethod?.closeMethod(false);
            return;
        }
        responseSetterMethod((prev) => {
            return { ...prev, ["saveAsTemplateLoading"]: true }
        });
    } else {
        responseSetterMethod((prev) => {
            return { ...prev, ["checklistCreationLoading"]: true }
        });
    };
    console.log('payload:', payload)

    // Checking the if any attribute name are same or not?
    let hasMap = {};
    let isAllAttributeNamesAreUnique = true;
    payload && payload?.dataSets?.length && payload?.dataSets?.forEach((item, index) => {
        if (isAllAttributeNamesAreUnique) {
            const key = item?.fieldName.trim().toString()
            if (hasMap[key] || key === "") {
                let message = null;
                message = `SI.NO ${index + 1} : "${item?.fieldName}" attribute is already present, please change the name!.`
                handleShowErrorAndWarning({ dispatch, type: "error", message, showTime: 5000 });
                isAllAttributeNamesAreUnique = false
            }
            else {
                hasMap[key] = 1;
            };
        }
    });

    if (!isAllAttributeNamesAreUnique) {
        responseSetterMethod((prev) => {
            return { ...prev, ["saveAsTemplateLoading"]: false, ["checklistCreationLoading"]: false }
        })
        isDuplicateTemplateSetterMethod && isDuplicateTemplateSetterMethod?.closeMethod(false);
        return; // flag for restrict if the duplicate attribute name found.
    }


    let isDuplicateTemplateName = false;
    doPostApiCall({ url: AppUrl.createAndSaveAsTemplate, payload, queryParams: { checklistId, isGeneralTemplate } }).then(response => {
        console.log('response:', response)
        if (response.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS) {
            if (isGeneralTemplate) {
                responseSetterMethod((prev) => {
                    handleShowErrorAndWarning({ dispatch, type: "success", message: "Template has been saved successfully.", showTime: 5000 });
                    isDuplicateTemplateSetterMethod && isDuplicateTemplateSetterMethod?.closeMethod(false);
                    return { ...prev, ["saveAsTemplateLoading"]: false, ["isSaveAsTemplate"]: true };
                });
            } else {
                handleShowErrorAndWarning({ dispatch, type: "success", message: `Checklist Created Successfully!.`, showTime: 5000 })
                responseSetterMethod((prev) => {
                    return { ...prev, ["checklistCreationLoading"]: false }
                })
                Navigate("/checklists");
            };
        }
        else if (response.response?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_BAD_REQUEST_ERROR) {
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${response?.response?.data?.message}.`, showTime: 5000 })
            isDuplicateTemplateSetterMethod && isDuplicateTemplateSetterMethod?.setLcValues(prev => {
                prev["templateNameErrorHandling"]?.errorSetterMethod("");
                prev["templateNameErrorHandling"]?.errorSetterMethod("Duplicate template name, Please change the template name!");
                prev["templateNameErrorHandling"]?.errorActivatorMethod(true);
                return { ...prev, ["isDuplicateTemplate"]: true, }
            })
            responseSetterMethod((prev) => {
                return { ...prev, ["saveAsTemplateLoading"]: false, ["checklistCreationLoading"]: false }
            })
        }
    }).finally(() => {
        responseSetterMethod((prev) => {
            return { ...prev, ["saveAsTemplateLoading"]: false, ["checklistCreationLoading"]: false }
        });
    })
};


export const handleUpdateChecklistDataSets = ({
    structureId,
    payload,
    dispatch,
    Navigate,
    responseSetterMethod
}) => {
    responseSetterMethod((prev) => {
        return { ...prev, ["updatedTemplateLoading"]: true }
    });

    // Checking the if any attribute name are same or not?
    let hasMap = {};
    let isAllAttributeNamesAreUnique = true;
    payload && payload?.dataSets?.length && payload?.dataSets?.forEach((item, index) => {
        if (isAllAttributeNamesAreUnique) {
            const key = item?.fieldName.trim().toString()
            if (hasMap[key] || key === "") {
                let message = null;
                message = `SI.NO ${index + 1} : "${item?.fieldName}" attribute is already present, please change the name!.`
                handleShowErrorAndWarning({ dispatch, type: "error", message, showTime: 5000 });
                isAllAttributeNamesAreUnique = false
            }
            else {
                hasMap[key] = 1;
            };
        }
    });

    if (!isAllAttributeNamesAreUnique) {
        responseSetterMethod((prev) => {
            return { ...prev, ["updatedTemplateLoading"]: false }
        });
        return; // flag for restrict if the duplicate attribute name found.
    }


    doPatchApiCall({ url: `${AppUrl.updatedTemplate}/${structureId}`, payload }).then(response => {
        console.log('responseUpdate:', response)
        const result = {
            status: response?.status,
            message: response?.data?.message,
        };

        if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS) {
            handleShowErrorAndWarning({ dispatch, type: "success", message: `${result?.message}!.`, showTime: 5000 })
            responseSetterMethod((prev) => {
                return { ...prev, ["updatedTemplateLoading"]: false }
            });
            Navigate("/checklists");
        }
        else if (result.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_BAD_REQUEST_ERROR) {
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${response?.response?.data?.message}.`, showTime: 5000 })
            responseSetterMethod((prev) => {
                return { ...prev, ["updatedTemplateLoading"]: false }
            });
        }
    }).finally(() => {
        responseSetterMethod((prev) => {
            return { ...prev, ["updatedTemplateLoading"]: false }
        });
    })
};




export const handleChangeChecklistStatus = async ({ responseSetterMethod, uniqueKey, checklistId, dispatch, payload, isGetResponse, setRefreshApi }) => {
    const lcPayload = { status: payload.status }
    try {
        const response = await changeChecklistStatusAPI({ checklistId, payload: lcPayload })
        if (response.status == 200 || response.status == 201) {
            handleShowErrorAndWarning({ dispatch, type: "success", message: `Status has been changed.`, showTime: 5000 })
        } else {
            handleShowErrorAndWarning({ dispatch, type: "error", message: `Something Went wrong.`, showTime: 5000 })
        }
    }
    catch (err) {
        // Handle errors, including login expiration and display error messages.
        handleLoginExpiry(err, dispatch);
        // Check if the error status is not 401 (Unauthorized).
        if (err?.response?.data?.status !== 401) {
            // Show an error message with the specific error details.
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${err.response.data.message}`, showTime: 5000 })
            // Log the error in the audit log.
            const error = err.response.data.error;
            postAuditLog({ action: "Change Checklist Status", message: error });
        }
    }
    finally {
        setRefreshApi && setRefreshApi()
        responseSetterMethod && responseSetterMethod(prev => {
            return { ...prev, "reviseLoading": false }
        })
    }
}

export const handleGetValidateRequests = async ({ responseSetterMethod, uniqueKey, checklistId, dispatch }) => {
    try {
        const response = await getValidateRequestsAPI({ checklistId })
        if (response.status == 200 || response.status == 201) {
            const data = response?.data?.result
            responseSetterMethod(prev => {
                return { ...prev, [uniqueKey]: data?.data }
            });
        } else {
            handleShowErrorAndWarning({ dispatch, type: "error", message: `Something Went wrong.`, showTime: 5000 })
        }
    }
    catch (err) {
        // Handle errors, including login expiration and display error messages.
        handleLoginExpiry(err, dispatch);
        // Check if the error status is not 401 (Unauthorized).
        if (err?.response?.data?.status !== 401) {
            // Show an error message with the specific error details.
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${err.response.data.message}`, showTime: 5000 })
            // Log the error in the audit log.
            const error = err.response.data.error;
            postAuditLog({ action: "Get Checklist Validate Request", message: error });
        }
    }
    finally {
    }
}

export const handleCreateValidateRequests = async ({ responseSetterMethod, uniqueKey, checklistId, dispatch }) => {
    responseSetterMethod(prev => {
        return { ...prev, [uniqueKey]: true }
    });

    try {
        const response = await createValidateRequestsAPI({ checklistId })
        if (response.status == 200 || response.status == 201) {
            handleChangeChecklistStatus({ checklistId, dispatch, payload: { status: "pendingApproval", isGetResponse: true } })
            handleGetValidateRequests({ dispatch, responseSetterMethod, uniqueKey: "validateRequestServerResponse", checklistId })
            handleShowErrorAndWarning({ dispatch, type: "success", message: `Request has been sent for approval.`, showTime: 5000 })
        } else {
            handleShowErrorAndWarning({ dispatch, type: "error", message: `Something Went wrong.`, showTime: 5000 })
        }
    }
    catch (err) {
        // Handle errors, including login expiration and display error messages.
        handleLoginExpiry(err, dispatch);
        // Check if the error status is not 401 (Unauthorized).
        if (err?.response?.data?.status !== 401) {
            // Show an error message with the specific error details.
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${err.response.data.message}`, showTime: 5000 })
            // Log the error in the audit log.
            const error = err.response.data.error;
            postAuditLog({ action: "Get Checklist Validate Request", message: error });
        }
    }
    finally {
        responseSetterMethod(prev => {
            return { ...prev, [uniqueKey]: false }
        });

    }

}

export const handleApproveTheValidateRequest = async ({ responseSetterMethod, uniqueKey, checklistId, requestId, dispatch, payload, setRefreshApi }) => {

    try {
        const response = await approveTheValidateRequestAPI({ requestId, payload })
        if (response.status == 200 || response.status == 201) {
            handleChangeChecklistStatus({ checklistId, dispatch, payload: { status: payload?.status, isGetResponse: true }, setRefreshApi })
            handleGetValidateRequests({ dispatch, responseSetterMethod, uniqueKey: "validateRequestServerResponse", checklistId })
        } else {
            handleShowErrorAndWarning({ dispatch, type: "error", message: `Something Went wrong.`, showTime: 5000 })
        }
    }
    catch (err) {
        // Handle errors, including login expiration and display error messages.
        handleLoginExpiry(err, dispatch);
        // Check if the error status is not 401 (Unauthorized).
        if (err?.response?.data?.status !== 401) {
            // Show an error message with the specific error details.
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${err.response.data.message}`, showTime: 5000 })
            // Log the error in the audit log.
            const error = err.response.data.error;
            postAuditLog({ action: "Approval Checklist Validate Request", message: error });
        }
    }
    finally {
        responseSetterMethod(prev => {
            return { ...prev, [uniqueKey]: false }
        });
        setRefreshApi && setRefreshApi()
    }
}


export const handleGetChecklistStatus = async ({ responseSetterMethod, dispatch }) => {

    responseSetterMethod(prev => {
        return { ...prev, ["loading"]: true }
    });

    try {
        const response = await doGetApiCall({ url: AppUrl.getChecklistStatuses })
        if (response.status == 200 || response.status == 201) {
            responseSetterMethod(prev => {
                return { ...prev, ["checklistStatuses"]: response?.data?.result }
            });
        }
    }
    catch (err) {
        // Handle errors, including login expiration and display error messages.
        handleLoginExpiry(err, dispatch);
        // Check if the error status is not 401 (Unauthorized).
        if (err?.response?.data?.status !== 401) {
            // Show an error message with the specific error details.
            handleShowErrorAndWarning({ dispatch, type: "error", message: `${err.response.data.message}`, showTime: 5000 })
            // Log the error in the audit log.
            const error = err.response.data.error;
            postAuditLog({ action: "Fetch All Checklist Status", message: error });
        }
    }
    finally {
        responseSetterMethod(prev => {
            return { ...prev, ["loading"]: false }
        });
    }
}


export const handleKeyGenerator = ({ timePeriod, specificDays, occursOnSpecificWeekDay, shiftTiming }) => {
    console.log('specificDays:', specificDays)
    if (timePeriod === "Month") {
        return specificDays + "-" + shiftTiming?.label?.split(" ").join("")
    } else {
        return occursOnSpecificWeekDay?.join("_") + "-" + shiftTiming?.label?.split(" ").join("")
    }
}


// Update the filled entry by creator
export const handleUpdateFilledEntry = ({ lcStateSetterMethod, payload, entryId, handleRefresh, handleFinallyExecutors, dispatch }) => {
    console.log('payload:', payload)
    lcStateSetterMethod(prev => ({ ...prev, ["updatedFilledEntryLoading"]: true }));
    doPatchApiCall({ url: `${AppUrl?.updateFilledEntry}/${entryId}`, payload }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status || response?.response?.status,
            // message: response?.data?.message
            message: response?.data?.message || response?.response?.data?.message
        }

        console.log('result:', result)
        if (result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS || result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS) {
            handleShowErrorAndWarning({ message: result?.message, dispatch, showTime: 5000, type: "success" });
            handleFinallyExecutors && handleFinallyExecutors?.setIsEditEntry();
            handleRefresh();
        } else {
            handleShowErrorAndWarning({ message: result?.message, dispatch, showTime: 5000, type: "error" });
        }

    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, ["updatedFilledEntryLoading"]: false }));
        handleFinallyExecutors && handleFinallyExecutors?.handleLoader();
    });
};

// Entering the detail in the entry by assignee.
export const handleFillingEntry = ({ lcStateSetterMethod, payload, entryId, handleRefresh, handleFinallyExecutors, dispatch }) => {
    lcStateSetterMethod(prev => ({ ...prev, ["assigneeEntryFillingLoading"]: true }));
    doPatchApiCall({ url: `${AppUrl?.fillEntry}/${entryId}`, payload }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status || response?.response?.status,
            message: response?.data?.message || response?.response?.data?.message

        }
        if (result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS || result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS) {
            handleShowErrorAndWarning({ message: result?.message, dispatch, showTime: 5000, type: "success" });
        } else {
            handleShowErrorAndWarning({ message: result?.message, dispatch, showTime: 5000, type: "error" });
        }
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, ["assigneeEntryFillingLoading"]: false }));
        handleRefresh();
        handleFinallyExecutors && handleFinallyExecutors?.setIsEditEntry();
    });
};


export const handleApproveFilledEntry = ({ lcStateSetterMethod, dispatch, entryId, handleRefresh }) => {
    lcStateSetterMethod(prev => ({ ...prev, ["approveFilledEntryLoading"]: true }));
    doPatchApiCall({ url: `${AppUrl?.approveFilledEntry}/${entryId}`, payload: { status: "completed" } }).then((response) => {
        const result = {
            status: response?.status || response?.response?.status,
            message: response?.data?.message || response?.response?.data?.message
        }

        if (result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_EDIT_SUCCESS || result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS) {
            handleShowErrorAndWarning({ message: result?.message, dispatch, showTime: 5000, type: "success" });
        } else {
            handleShowErrorAndWarning({ message: result?.message, dispatch, showTime: 5000, type: "error" });
        }

    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, ["approveFilledEntryLoading"]: false }));
        handleRefresh && handleRefresh();
    })
};




