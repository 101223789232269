import "./App.css";
import ClonosRoutes from "./Routes";
import 'typeface-roboto'
import socket from "./services/socket";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { handleUpdateNotificationsStore } from "./Clonos_Modules/clonos-notifications/notifications-drawer/notifications-drawer-manager";
import useFunctionDebounce from "./CustomHooks/FunctionDebounceHook";
import { consoleModifier } from "./utils/common-managers";


let toggle = true;
let notificationQueue = [];
const App = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(store => store.notificationsData.notifications);
    const [_, setStoreNotificationReference] = useState(notifications);
    const [lcNotification, setNotification] = useState({});


    console.log('lcNotification:', lcNotification)
    console.log('notificationQueue:', notificationQueue)
    console.log('notifications:', notifications)

    const handleToggle = () => {
        toggle = !toggle;
    };

    const handleClearNotificationQueue = () => {
        console.log('Clearing notification queue...');
        setNotification(notificationQueue);
        handleToggle();
        notificationQueue = [];
    };

    const debouncedClearNotificationQueue = useFunctionDebounce(handleClearNotificationQueue, 1000); // 1 second debounce


    /**
     * Initializes WebSocket connection and sets up event listeners for 'connection', 'notification', 'error', and 'disconnected' events.
     * Cleans up event listeners on component unmount.
     */
    useEffect(() => {

        consoleModifier();

        socket.on('connection', (data) => {
            // Handle connection event
        });

        socket.on('notification', (data) => {
            console.log('notificationData:', data)
            const lastElement = notificationQueue?.[notificationQueue?.length - 1] ? notificationQueue?.[notificationQueue?.length - 1] : null;
            if (lastElement && lastElement?.id !== data?.id) {
                notificationQueue.push(data);
            } else if (notificationQueue?.length === 0) {
                notificationQueue.push(data);
            };
            debouncedClearNotificationQueue();
        });

        socket.on('error', (error) => {
            console.log('Connection Error:', error);
        });

        socket.on('disconnected', (message) => {
            console.log('message:', message);
        });

        return () => {
            socket.off('disconnected');
            socket.off('notification'); // Cleanup other listeners
            socket.off('error');
            socket.off('connection');
            notificationQueue = [];
        };
    }, []);


    const processNotificationsWithDelay = (notificationsArray, delay = 100) => {
        let i = 0; // Initialize the index
        let timeOutId = null;
        const processNextNotification = () => {
            if (i < notificationsArray.length) {
                const notificationElement = notificationsArray[i];
                setStoreNotificationReference(prev => {
                    console.log('prev:', prev)
                    const temp = prev?.length ? prev : [];
                    handleUpdateNotificationsStore({
                        storedNotifications: temp,
                        currentNotification: notificationElement,
                        dispatch
                    });
                    i++;
                    timeOutId = setTimeout(processNextNotification, delay);
                    return prev;
                })
            }
        };
        processNextNotification();
        clearTimeout(timeOutId);
    };

    /**
     * Updates the notifications store whenever the toggle state changes and lcNotification has data.
     */
    useEffect(() => {
        console.log('notificationQueue:', notificationQueue)
        if (Object.keys(lcNotification).length) {
            // Hitting Notifications
            processNotificationsWithDelay(lcNotification);
        };

    }, [toggle]);

    useEffect(() => {
        setStoreNotificationReference(notifications);
    }, [notifications?.length]);

    return <ClonosRoutes />;

}

export default App;
