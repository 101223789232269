import React, { useState } from 'react'
import Styles from "./DynamicDropdown.module.css"
import Modal from '../Modal/Modal'
import closeIcon from "../../../assets/UIUX/icons/WO/circle-x-filled.svg"
import searchIcon from "../../../assets/UIUX/icons/WO/search-1.svg"
import ClonosSpinner from "../../../assets/Clonos Common Indicators/Clonos_Spinner.svg"
import { useEffect } from 'react'
import useToggler from '../../../CustomHooks/TogglerHook'
import { IoIosClose } from "react-icons/io"
import { CLONOS_GLOBAL_COLOUR_RED, CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, CLONOS_GLOBAL_ERROR_HANDLING_SIZE } from '../../../Constants/Constant'
import { ErrorSVGCom } from '../../../assets/svg/ErrorHandling'
import SEARCHICON from "../../../assets/UIUX/icons/search.svg";



/**
 * Dynamic Dropdown component.
 * 
 * @param {object} props - The component's input properties.
 * @property {string} title - The title of the dropdown.
 * @property {string} labelActivator - The label for the activator.
 * @property {boolean} isMandatory - Indicates if the dropdown is mandatory.
 * @property {boolean} isOpen - Indicates if the dropdown is open.
 * @property {function} isOpenMethod - The method to toggle the dropdown open/closed.
 * @property {boolean} isSearchable - Indicates if the dropdown is searchable.
 * @property {boolean} isCheckbox - Indicates if the dropdown allows multiple selections.
 * @property {boolean} isDynamicLoad - Indicates if data should be loaded dynamically.
 * @property {boolean} isActivator - Indicates if an activator is used to control the dropdown.
 * @property {boolean} isAsset - Indicates if the dropdown is for assets.
 * @property {boolean} isUser - Indicates if the dropdown is for users.
 * @property {funciton} handleDynamicSearch - Indicates if the dropdown has dynamic search.
 * @property {string} placeholder - The placeholder text for the search input.
 * @property {string} heading - The heading for the dropdown.
 * @property {array} data - The data for the dropdown options.
 * @property {function} handleMoreData - The method to handle loading more data.
 * @property {string} url - The URL for the dropdown.
 * @property {number} tabIndex - tabIndex for input tag.
 * @property {array} defaultValue - The default selected values.
 * @property {array} populatedFields - Fields that we want to show, otherthan name fields.
 * @property {function} handleGetValues - The method to handle getting selected values.
 * @property {function} handleGetErrorMethods - The method to handle errors in the dropdown.
 * @property {function} handleGetErrorActivatorInformation - The method to handle errors in the activator.
 * @property {string} errorMessage - The error message for the dropdown.
 * @property {string} noDataMessage - Particular message for data unavailability.
 * @property {function} handleGetErrorActivatorInformation - This function will handle all kind of error handling, that returns one object.
 * @property {function} handleGetErrorActivatorInformation.errorActivatorMethod  - This function will trigger or allow to show the error, it takes boolean value (true/false).
 * @property {function} handleGetErrorActivatorInformation.errorSetterMethod  - This function can set the error massage, it takes string.
 * @property {function} handleGetErrorActivatorInformation.type  - "type" represent that which kind of components is it like, "input, select, textarea"
 * @property {function} handleGetErrorActivatorInformation.uniqueKey  - "uniqueKey" will be be unique string that represent specific element.
 * @property {function} handleGetErrorActivatorInformation.value  - "value" Initially will be false that you need to change once that specific element has come value. and make it as true.
 * @returns {React.Component} - ClonosSelect component.
 */

const DynamicDropdown = (
    {
        url,
        data,
        name,
        title,
        label,
        isOpen,
        heading,
        tabIndex,
        uniqueKey,
        disabled,
        isCheckbox,
        isMandatory,
        isActivator,
        placeholder,
        isSearchable,
        isOpenMethod,
        defaultValue,
        errorMessage,
        isDynamicLoad,
        noDataMessage,
        labelActivator,
        isLabel = true,
        handleMoreData,
        handleGetValues,
        handleDynamicSearch,
        populatedFields = [],
        handleGetErrorMethods,
        handleGetErrorActivatorInformation,
    }
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deselectedItems, setDeselectedItems] = useState([]);
    const [lcData, setLcData] = useState([]);
    const [selectedItemsIds, setSelectedItemsIds] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [lcIsOpen, setLcIsOpen] = useState(false);
    const [isAnyValueSelected, setIsAnyValueSelected] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [lcErrorMessage, setLcErrorMessage] = useState(errorMessage);


    const loadMoreData = () => {
        // Simulated data loading for "Load More" (replace with your data loading logic)
        setIsLoading(true);
        setTimeout(() => {
            handleMoreData && handleMoreData({ isActivator, uniqueKey })
            setIsLoading(false);
        }, 1000); // Simulated delay for loading data
    };


    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

        if (scrollTop + clientHeight >= scrollHeight - 50 && !isLoading) {
            // When the user is near the bottom, load more data
            loadMoreData();
        }
    };


    /**
     * Handles the selection or deselection of items in a dynamic dropdown.
     *
     * @param {object} props - The function's input properties.
     * @property {object} e - The event object, which can be used to check the state of the checkbox.
     * @property {object} props.item - The item being selected or deselected.
     */
    const handleGetValuesLc = ({ e, item }) => {
        if (isCheckbox) {
            const checkboxInputRef = document.getElementById(item.id);
            if (e?.target?.checked || !checkboxInputRef.checked) {
                checkboxInputRef.checked = true;
                // Clear error message and indicate that at least one item is selected
                showErrorMessage && setShowErrorMessage(false);
                setIsAnyValueSelected(true);

                // Update the selected items and their IDs
                setSelectedItems((prev) => {
                    let updatedState = [...prev, item];
                    // Handle the selected values, e.g., for dynamic dropdown
                    handleGetValues && handleGetValues({ "name": name, uniqueKey, updatedValue: updatedState, selectedOption: updatedState, deselectedOptions: deselectedItems.filter(el => el !== item?.id), "type": "dynamicDropdown" });
                    return updatedState;
                });

                setDeselectedItems((prev) => prev?.filter(el => el !== item?.id))

                let temp = selectedItems.map(ele => ele.id);
                setSelectedItemsIds([...temp, item?.id]);
            } else {
                checkboxInputRef.checked = false;
                // Handle deselection
                let filteredData = selectedItems.filter(ele => ele.id !== item.id);
                if (filteredData.length === 0) {
                    setIsAnyValueSelected(false);
                    setShowErrorMessage(true);
                }
                if (!filteredData.length) setIsAnyValueSelected(false);

                let temp = filteredData.map(ele => ele.id);
                setSelectedItemsIds([...temp]);

                setDeselectedItems((prev) => {
                    const temp_arr = [...prev, item?.id];
                    // Handle the deselected values, e.g., for dynamic dropdown
                    handleGetValues && handleGetValues({ "name": name, uniqueKey, updatedValue: filteredData, selectedOption: filteredData, deselectedOptions: temp_arr, "type": "dynamicDropdown" });
                    return temp_arr
                })
                setSelectedItems(filteredData);
            }
        }
        else {
            // Handle selection without checkboxes
            setIsAnyValueSelected(true);
            setShowErrorMessage(false);
            setSelectedItems([item]);
            // Handle the selected values, e.g., for dynamic dropdown
            handleGetValues && handleGetValues({ "name": name, uniqueKey, updatedValue: [item], selectedOption: [item], "type": "dynamicDropdown" });

            // Conditionally set the open state based on 'isActivator'
            isActivator ? setLcIsOpen(false) : isOpenMethod();
        }
    };


    /**
     * This function is getting use for making the all states as empty.
     */
    const handleReset = () => {
        setSelectedItemsIds([]);
        setSelectedItems([]);
        setIsAnyValueSelected(false);
    }


    useEffect(() => {
        // Define a function to execute the debounce logic
        const debounce = setTimeout(() => {
            setDebouncedValue(inputValue);
            handleSearch({ name: inputValue })
        }, 500); // Adjust the debounce delay as needed (e.g., 500ms)

        setLcErrorMessage(errorMessage ? errorMessage : `Please fill the ${title || label} field!`);

        // Clean up the timeout when the component unmounts or when inputValue changes
        return () => {
            clearTimeout(debounce);
        };

    }, [inputValue]);

    useEffect(() => {
        if (data) setLcData(data)
    }, [data])

    useEffect(() => {
        handleGetErrorMethods && handleGetErrorMethods({ "name": name, "selectedOption": "", setShowErrorMessage, type: "dynamicDropdown", valueSetterMethod: setIsAnyValueSelected, isMandatory })
        if (defaultValue) {
            setSelectedItems(defaultValue)
            setIsAnyValueSelected(true)
            let tempp = defaultValue?.length > 0 && defaultValue.map(ele => ele?.id);
            setSelectedItemsIds(tempp)
        }
        // if (handleGetErrorActivatorInformation && isMandatory) {
        //     handleGetErrorActivatorInformation(
        //         {
        //             uniqueKey,
        //             type: "dynamicDropdown",
        //             value: (typeof (defaultValue) == "string" || defaultValue?.length > 0) || (Array.isArray(defaultValue) == "array" || defaultValue?.length > 0) ? true : false,
        //             errorActivatorMethod: setShowErrorMessage,
        //             errorSetterMethod: setLcErrorMessage,
        //             resetMethod: handleReset
        //         }
        //     )
        // }
        // else if (handleGetErrorActivatorInformation && !isMandatory) {
        //     handleGetErrorActivatorInformation(
        //         {
        //             uniqueKey,
        //             type: "dynamicDropdown",
        //             value: true,
        //             errorActivatorMethod: setShowErrorMessage,
        //             errorSetterMethod: setLcErrorMessage,
        //             resetMethod: handleReset
        //         }
        //     )
        // }
        handleGetErrorActivatorInformation && handleGetErrorActivatorInformation(
            {
                uniqueKey,
                type: "dynamicDropdown",
                value: isMandatory ? (typeof (defaultValue) == "string" || defaultValue?.length > 0) || (Array.isArray(defaultValue) == "array" || defaultValue?.length > 0) ? true : false : true,
                errorActivatorMethod: setShowErrorMessage,
                errorSetterMethod: setLcErrorMessage,
                resetMethod: handleReset
            }
        )
    }, [defaultValue && defaultValue[0]?.name])

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };


    const handleSearch = ({ name }) => {
        if (handleDynamicSearch) {
            handleDynamicSearch && handleDynamicSearch({ uniqueKey, input: name })
        } else {
            let filteredData = data && data.filter((ele) => {
                let needToSearch = name.toUpperCase();
                let element = ele.name.toUpperCase();
                let count = 0
                for (let i = 0; i < needToSearch.length; i++) {
                    if (element[i] == needToSearch[i]) count++
                }
                if (count == needToSearch.length) return ele
            })
            if (name === "" && data && data.length > 0) setLcData(data)
            else if (name && name.length > 0) setLcData(filteredData)
        }
    }
    return (
        <>
            {
                isActivator && <>
                    <div className={Styles.dynamicDropdown_container}>
                        {
                            (isLabel && label) && <label> {(title || label) ? title || label : "Heading that you can change"} {isMandatory && <sup className={Styles.dynamicDropdown_label}>*</sup>}</label>
                        }
                        <div
                            tabIndex={tabIndex}
                            className={Styles.dynamicDropdown_isActivator}
                            style={{
                                borderColor: showErrorMessage ? CLONOS_GLOBAL_COLOUR_RED : "#8CA1C4",
                                cursor: disabled ? "not-allowed" : "pointer",
                                backgroundColor: disabled ? CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT : "",
                            }}
                            onClick={() => (isActivator && !disabled) ? setLcIsOpen(true) : isOpenMethod()}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    isActivator ? setLcIsOpen(true) : isOpenMethod()
                                }
                            }}
                        >
                            <span>{isAnyValueSelected ? isCheckbox ? (labelActivator || label) : selectedItems[0]?.name : (labelActivator || label)}</span>
                            <div className={Styles.ddi_controllers}>
                                {
                                    isAnyValueSelected && !isCheckbox && <IoIosClose style={{ cursor: disabled ? "not-allowed" : "pointer" }} fontSize="23px" color="#a3a3a7" onClick={(e) => {
                                        if (!disabled) {
                                            e.stopPropagation()
                                            setIsAnyValueSelected(false)
                                            isMandatory && setShowErrorMessage(true)
                                            setSelectedItems([])
                                            handleGetValues && handleGetValues({ "name": name, uniqueKey, updatedValue: null, selectedOption: null, "type": "dynamicDropdown" })
                                        }
                                    }} />
                                }
                                <img src={url ? url : SEARCHICON} alt="searchIcon" />
                            </div>
                        </div>
                        {
                            isCheckbox && <div className={Styles.ddi_checkbox_controller}>
                                {
                                    selectedItems && selectedItems.length > 0 && selectedItems?.map((ele) => {
                                        return <div className={Styles.ddi_checkbox_controller_item}><span>{ele.name}</span> <IoIosClose fontSize="23px" onClick={() => {
                                            let temp = selectedItemsIds.filter((lcItem => lcItem !== ele.id))
                                            let removedValues = [];
                                            setSelectedItemsIds(temp)
                                            setDeselectedItems((prev) => ([...prev, ele?.id]))
                                            return setSelectedItems((prev) => {
                                                let updatedValues = prev.filter((item) => item.id != ele.id)
                                                !updatedValues.length && setShowErrorMessage(true);
                                                handleGetValues && handleGetValues({ "name": name, uniqueKey, updatedValue: updatedValues, selectedOption: updatedValues, deselectedOptions: [...deselectedItems, ele?.id], "type": "dynamicDropdown" })
                                                return updatedValues
                                            })
                                        }} /> </div>
                                    })
                                }
                            </div>
                        }
                        {showErrorMessage && <span style={{ color: CLONOS_GLOBAL_COLOUR_RED }} className={Styles.error_handling}> <ErrorSVGCom size={CLONOS_GLOBAL_ERROR_HANDLING_SIZE} color={CLONOS_GLOBAL_COLOUR_RED} /> {lcErrorMessage}</span>}
                    </div>
                </>
            }

            <Modal isOpen={isActivator ? lcIsOpen : isOpen} closeModalMethod={() => {
                isActivator ? setLcIsOpen(false) : isOpenMethod()
                isAnyValueSelected ? setShowErrorMessage(false) : isMandatory && setShowErrorMessage(true)
            }}>
                <div className={Styles.dd_main_container}>
                    <div className={Styles.dd_body}> {/* dd: dynamic dropdown */}
                        <header className={Styles.ddb_header}> {/* ddb: dynamic dropdown body */}
                            <span>{heading}</span>
                            <div><img onClick={() => isActivator ? setLcIsOpen(false) : isOpenMethod()} src={closeIcon} alt='Close Button' /></div>
                        </header>

                        <section>
                            <div>
                                {
                                    isSearchable && <div className={Styles.ddb_search} >
                                        <img src={searchIcon} alt="Search" />
                                        <input
                                            placeholder={placeholder}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                }
                            </div>
                            <div className={Styles.ddb_content} onScroll={isDynamicLoad && handleScroll}>
                                {lcData?.map((item, index) => {
                                    {
                                        return <div key={index} className={Styles.ddbc_item} onClick={() => handleGetValuesLc({ item })}> {/* ddbc: dynamic dropdown body content */}
                                            {isCheckbox && <div>
                                                <input
                                                    className={Styles.checkbox_input}
                                                    id={item.id}
                                                    type='checkbox'
                                                    checked={selectedItemsIds.length > 0 && selectedItemsIds?.includes(item.id)}
                                                />
                                            </div>}
                                            <div className={Styles.ddbc_item_values}>
                                                <span>{item?.name}</span>
                                                {
                                                    populatedFields.length > 0 && (populatedFields || []).map((lcItem) => <span>{item[lcItem]}</span>)
                                                }
                                            </div>
                                        </div>
                                    }
                                })}
                                {
                                    isLoading && <img src={ClonosSpinner} />
                                }
                                {
                                    !data?.length && <section><span>{noDataMessage ? noDataMessage : `No ${title || (label || "")} Found!`}</span></section>
                                }
                            </div>
                        </section>
                        <footer className={Styles.ddb_footer}>
                            <div className={Styles.ddbf_controller}> {/* ddbf: dynamic dropdown body footer */}
                                <span onClick={() => {
                                    isActivator ? setLcIsOpen(false) : isOpenMethod()
                                    isMandatory && isAnyValueSelected == false && setShowErrorMessage(true)
                                    // setSelectedItems([])
                                }}>Cancel</span>
                                <button
                                    disabled={selectedItems?.length > 0 ? false : true}
                                    onClick={() => {
                                        isActivator ? setLcIsOpen(false) : isOpenMethod()
                                        isAnyValueSelected ? setShowErrorMessage(false) : setShowErrorMessage(true)
                                    }}>Ok</button>
                            </div>
                        </footer>
                    </div >
                </div>
            </Modal >
        </>
    )
}

export default DynamicDropdown