import { Routes, Route, useNavigate, BrowserRouter } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import ClonosAllUsers from "./components/user/ClonosAllUsers";

import Index from "./ResearchAndDevelopment";
import ClonosPrivateRoute from "./utils/ClonosPrivateRoute";
import Addons from "./Addons";
import ClonosLoader from "./components/CommonComponents/ClonosLoader/ClonosLoader";
import NotFound from "./components/not-found/NotFound";

const ClonosPublicRoute = React.lazy(() => import("./utils/ClonosPublicRoute"));

const AssetsPage = React.lazy(() => import("./Clonos_Modules/ClonosAssets/ViewAsset/AssetsList"));

const CreateAssetPage = React.lazy(() => import("./Clonos_Modules/ClonosAssets/RegisterAsset/CreateAsset"));

const EditAssetPage = React.lazy(() => import("./Clonos_Modules/ClonosAssets/edit-asset/EditAsset"));

const ViewAssetPage = React.lazy(() => import("./Clonos_Modules/ClonosAssets/ViewAsset/ViewAssetDetails"));

const TaskListPage = React.lazy(() => import("./Clonos_Modules/TaskLibrary/TasksListing/TasksListing"));

const MaintenancePlanPage = React.lazy(() => import("./Clonos_Modules/ScheduledMaintenance/CreateMaintenanceTask"));

const MaintenanceListPage = React.lazy(() => import("./Clonos_Modules/ScheduledMaintenance/ScheduleMaintenanceList"));

const ViewMaintenancePage = React.lazy(() => import("./Clonos_Modules/ScheduledMaintenance/ViewScheduledMaintenance"));

const TaskCreationPage = React.lazy(() => import("./Clonos_Modules/TaskLibrary/TasksCreation/TasksCreation"));

const TaskViewPage = React.lazy(() => import("./Clonos_Modules/TaskLibrary/ViewTask"));

const WorkOrderCreationPage = React.lazy(() => import("./Clonos_Modules/WorkOrder/WorkOrder"));

const WorkOrderViewPage = React.lazy(() => import("./Clonos_Modules/WorkOrder/WorkOrderView"));

const WorkOrderListPage = React.lazy(() => import("./Clonos_Modules/WorkOrder/WorkOrderList"));

const WorkOrderEditPage = React.lazy(() => import("./Clonos_Modules/WorkOrder/WorkOrderEdit"));

// Logs Module Imports 

const UserDetailViewPage = React.lazy(() => import("./Clonos_Modules/Auth/ClonosUser/UserView/ViewUserDetails"));

const UsersPage = React.lazy(() => import("./Clonos_Modules/Auth/ClonosUser/UsersList/UsersList"));

const PermissionsPage = React.lazy(() => import("./Clonos_Modules/Auth/ClonosPermissions/Permissions"));

const CreateUserPage = React.lazy(() => import("./Clonos_Modules/Auth/ClonosUser/UserCreationAndEdit/UserCreation"));

const EditUserPage = React.lazy(() => import("./Clonos_Modules/Auth/ClonosUser/UserCreationAndEdit/UserEdit"));

const CreateAndEditDocumentPage = React.lazy(() => import("./Clonos_Modules/ClonosDocuments/CreateAndEditDocument"));

const ListDocumentsPage = React.lazy(() => import("./Clonos_Modules/ClonosDocuments/DocumentsList"));

const ClonosLoginPage = React.lazy(() => import("./Clonos_Modules/Auth/ClonosLogin/ClonosLogin"));

const ClonosDashboardPage = React.lazy(() => import("./Clonos_Modules/ClonosDashboard/Dashboard"));

const ClonosListPlant3DPage = React.lazy(() => import("./Clonos_Modules/Plant3D/ListPlant3D/ListPlant3D"))

const ClonosPlant3D = React.lazy(() => import("./Clonos_Modules/Plant3D/CreatePlant3D/CreatePlant3D"))

const ClonosList3DLibrary = React.lazy(() => import('./Clonos_Modules/Plant3D/3DLibrary/List3DLibrary'))

const ClonosViewPlant3DPage = React.lazy(() => import("./Clonos_Modules/Plant3D/ViewPlant3D"))

const ClonosImageEditorPage = React.lazy(() => import("./ResearchAndDevelopment/index"))

const SparesAndInventoryListPage = React.lazy(() => import("./Clonos_Modules/SparesAndInventory/SparesAndInventoryList"));

// const ReportsListPage = React.lazy(() => import("./Clonos_Modules/reports/reports-list/ReportsList"));

// const ReportsViewPage = React.lazy(() => import("./Clonos_Modules/reports/view-report-details/ViewReportDetails"))


// Checklist Imports 
const ChecklistCreatePage = React.lazy(() => import("./Clonos_Modules/ChecklistAndReports/ClonosChecklist/ChecklistCreation/CreateChecklistGeneralDetails"));
const CheckListPage = React.lazy(() => import("./Clonos_Modules/ChecklistAndReports/ClonosChecklist/ChecklistListing/ChecklistListing"));
const ChecklistViewPage = React.lazy(() => import("./Clonos_Modules/ChecklistAndReports/ClonosChecklist/ChecklistView/ChecklistView"));
const ChecklistTemplateCreationPage = React.lazy(() => import("./Clonos_Modules/ChecklistAndReports/ClonosChecklist/ChecklistCreation/ChecklistTemplateCreatePage"));
const ChecklistTemplateEditPage = React.lazy(() => import("./Clonos_Modules/ChecklistAndReports/ClonosChecklist/ChecklistCreation/ChecklistTemplateCreatePage"));
const RecurredChecklistListingPage = React.lazy(() => import("./Clonos_Modules/ChecklistAndReports/ClonosChecklist/ChecklistListing/RecurredChecklistListing/RecurredChecklistListing"))


// Logs Imports 
const LogCreatePage = React.lazy(() => import("./Clonos_Modules/LogAndReports/ClonosChecklist/ChecklistCreation/CreateChecklistGeneralDetails"));
const LogsListingPage = React.lazy(() => import("./Clonos_Modules/LogAndReports/ClonosChecklist/ChecklistListing/ChecklistListing"));
const LogViewPage = React.lazy(() => import("./Clonos_Modules/LogAndReports/ClonosChecklist/ChecklistView/ChecklistView"));
const LogTemplateCreationPage = React.lazy(() => import("./Clonos_Modules/LogAndReports/ClonosChecklist/ChecklistCreation/ChecklistTemplateCreatePage"));
const RecurredLogListingPage = React.lazy(() => import("./Clonos_Modules/LogAndReports/ClonosChecklist/ChecklistListing/RecurredChecklistListing/RecurredChecklistListing"))



const ClonosRoutes = () => {

  return <BrowserRouter>
    <Addons />
    <Suspense fallback={<ClonosLoader />}>
      <Routes>
        {/* no route  */}
        <Route path="*" element={<NotFound />} />

        <Route path={'/'} element={<ClonosPublicRoute />}>
          <Route index element={<ClonosLoginPage />} />
        </Route>
        <Route element={<ClonosPrivateRoute />}>
          <Route path="/dashboard" element={<ClonosDashboardPage />} />

          {/* Assets  */}
          <Route path="/assets" element={<AssetsPage />} />
          <Route path="/view-asset/:id" element={<ViewAssetPage />} />
          <Route path="/create-asset" element={<CreateAssetPage />} />
          <Route path="/edit-asset" element={<EditAssetPage />} />

          {/* Documents  */}
          <Route path="/create-document" element={<CreateAndEditDocumentPage />} />
          <Route path="/edit-document" element={<CreateAndEditDocumentPage mode={"edit"} />} />
          <Route path="/documents" element={<ListDocumentsPage />} />

          {/* Users  */}
          <Route path="/users" element={<UsersPage />} />
          <Route path="/profile/:id" element={<UserDetailViewPage />} />
          <Route path="/create-user" element={<CreateUserPage mode="create" />} />
          <Route path="/edit-user" element={<EditUserPage />} />
          <Route path="/permissions" element={<PermissionsPage />} />

          {/* Work Orders  */}
          <Route path="/work-orders" element={<WorkOrderListPage />} />
          <Route path="/view-work-order/:id" element={<WorkOrderViewPage />} />
          <Route path="/create-work-order" element={<WorkOrderCreationPage />} />
          <Route path="/edit-work-order" element={<WorkOrderEditPage />} />

          {/* Checklists */}
          <Route path="/checklists" element={<CheckListPage />} />
          <Route path="/view-checklist/:id" element={<ChecklistViewPage />} />
          <Route path="/create-checklist-generaldetails" element={<ChecklistCreatePage mode="create" />} />
          <Route path="/edit-checklist-generaldetails" element={<ChecklistCreatePage mode="edit" />} />
          <Route path="/create-checklist-template" element={<ChecklistTemplateCreationPage />} />
          <Route path="/edit-checklist-template/:id" element={<ChecklistTemplateEditPage mode="edit" />} />
          <Route path="/checklist-entries" element={<RecurredChecklistListingPage />} />

          {/* Logs  */}
          <Route path="/logs" element={<LogsListingPage />} />
          <Route path="/view-log/:id" element={<LogViewPage />} />
          <Route path="/create-log-generaldetails" element={<LogCreatePage mode="create" />} />
          <Route path="/edit-log-generaldetails" element={<LogCreatePage mode="edit" />} />
          <Route path="/create-log-template" element={<LogTemplateCreationPage />} />
          <Route path="/edit-log-template/:id" element={<LogTemplateCreationPage mode="edit" />} />
          <Route path="/log-entries" element={<RecurredLogListingPage />} />

          {/* Task Library  */}
          <Route path="/tasks-library" element={<TaskListPage />} />
          <Route path="/view-task/:id" element={<TaskViewPage />} />
          <Route path="/create-task" element={<TaskCreationPage />} />
          <Route path="/edit-task" element={<TaskCreationPage mode="edit" />} />

          {/* Schedule Maintenance  */}
          <Route path="/maintenance-plans" element={<MaintenanceListPage />} />
          <Route path="/view-maintenance-plan/:id" element={<ViewMaintenancePage />} />
          <Route path="/create-scheduled-maintenance" element={<MaintenancePlanPage />} />
          <Route path="/edit-scheduled-maintenance" element={<MaintenancePlanPage mode={"edit"} />} />

          {/* Plant 3D  */}
          <Route path="/plant3D-modules" element={<ClonosListPlant3DPage />} />
          <Route path="/create-plant3D" element={<ClonosPlant3D />} />
          <Route path="/edit-plant3D" element={<ClonosPlant3D mode="edit" />} />
          <Route path="/3D-library" element={<ClonosList3DLibrary />} />
          <Route path="/view-plant3D/:id" element={<ClonosViewPlant3DPage />} />

          {/* Image Editor */}
          {/* <Route path="/r-and-d" element={<ClonosImageEditorPage />} /> */}
          <Route path="/r-and-d" element={<ClonosImageEditorPage />} />

          {/* Spares & Inventory */}
          <Route path="/spares" element={<SparesAndInventoryListPage />} />

          {/* Reports */}
          {/* <Route path="/reports" element={<ReportsListPage/>}/>
          <Route path="/view-report-details" element={<ReportsViewPage/>} /> */}


        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
};

export default ClonosRoutes;
